import { ReactComponent as Heroad } from '../../../files/HEROAD_signup.svg'
import { ReactComponent as HeroadMobile } from '../../../files/HEROAD_mobile_signup.svg'
import { ReactComponent as LogoIcon } from '../../../files/logo_heroad_signup.svg'
import { ReactComponent as LogoMobileIcon } from '../../../files/logo_heroad_mobile_signup.svg'
import React, { useEffect, useReducer } from 'react'
import {
    ACTIONS,
    getInitialState,
    SalesReducer,
} from '../../../services/Reducers/SalesReducer'
import API from '../../../services/api'
import SelectDropDownList from '../../Commons/DropDownLists/SelectDropDownList'
import { ReactComponent as InlineButtonLoader } from '../../../files/inlineButtonLoader.svg'
import {
    ERROR_ACTIONS,
    ErrorReducer,
} from '../../../services/Reducers/ErrorReducer'
import Error from '../../Commons/Error'
import { emailIsValid } from '../../../tools/Utility'
import {
    AddressAutoCompleteReducer,
    getAddressInitialState,
} from '../../../services/Reducers/AddressAutoCompleteReducer'
import SocietyInformations from './SocietyInformations'
import PersonalInformations from './PersonalInformations'
import Payment from './Payment'
import { toast } from 'react-toastify'
import GeoapifyAutocomplete from '../GeoapifyAutoComplete'

const SalesContainer = () => {
    const [sales, dispatch] = useReducer(SalesReducer, [], getInitialState)
    const [errors, dispatchError] = useReducer(ErrorReducer, [])

    const [address, addressDispatch] = useReducer(
        AddressAutoCompleteReducer,
        { undefined },
        getAddressInitialState
    )

    const handleProduct = (value) => {
        dispatch({ type: ACTIONS.SET_PRODUCT, payload: value })
    }

    const handleLoading = (value) => {
        dispatch({ type: ACTIONS.SET_LOADING, payload: value })
    }

    const handleSignUpDone = (value) => {
        dispatch({ type: ACTIONS.SET_SIGNUP_DONE, payload: value })
    }

    const handleStripeClientSecretKeyAndCustomerId = (
        secretKey,
        customerId
    ) => {
        dispatch({
            type: ACTIONS.SET_STRIPE_CLIENT_SECRET_KEY_AND_CUSTOMER_ID,
            payload: {
                stripeClientSecretKey: secretKey,
                customerId: customerId,
            },
        })
    }

    const fetchProducts = () => {
        API.Stripe.listProducts().then((response) => {
            response.json().then((data) => {
                const products = data.products.map((product) => {
                    return {
                        label: `${product.name} ${product.price} €`,
                        value: product.id,
                        priceId: product.priceId,
                    }
                })
                dispatch({ type: ACTIONS.SET_PRODUCTS, payload: products })
            })
        })
    }

    const checkErrors = () => {
        dispatchError({
            type: ERROR_ACTIONS.CLEAR_ALL_ERRORS,
        })
        sales.address = address
        let msg = ''

        if (!sales.email) {
            msg = 'Veuillez renseigner un email.'
        } else if (!emailIsValid(sales.email)) {
            msg = 'Veuillez renseigner un email valide.'
        } else if (!sales.firstname) {
            msg = 'Veuillez renseigner un prénom.'
        } else if (!sales.lastname) {
            msg = 'Veuillez renseigner un nom.'
        } else if (sales.phone.length < 10) {
            msg = 'Veuillez renseigner un numéro de téléphone valide.'
        } else if (!sales.phone) {
            msg = 'Veuillez renseigner un numéro de téléphone.'
        } else if (sales.siren.toString().length !== 9) {
            msg += 'Veuillez renseigner un siren valide.'
        } else if (!sales.societyName) {
            msg += 'Veuillez renseigner le nom de la société.'
        } else if (!sales.address.city) {
            msg += 'Veuillez renseigner une ville.'
        } else if (sales.nbVehicles <= 0) {
            msg += 'Veuillez renseigner le nombre de véhicules de la société.'
        }
        if (msg !== '') {
            dispatchError({
                type: ERROR_ACTIONS.SET_ERROR,
                payload: {
                    id: msg,
                    message: msg,
                },
            })
            return true
        }
        return false
    }

    const createCustomerAndPaymentIntent = () => {
        if (!checkErrors()) {
            handleLoading(true)
            const data = {
                firstname: sales.firstname,
                lastname: sales.lastname,
                phone: sales.phone,
                email: sales.email,
                address: {
                    city: address.city,
                    street: address.street,
                    postalCode: address.zipCode,
                },
                siren: sales.siren,
                societyName: sales.societyName,
                nbVehicles: sales.nbVehicles,
            }
            API.Stripe.CreateCustomerAndPaymentIntent(data)
                .then((response) => {
                    response.json().then((data) => {
                        handleStripeClientSecretKeyAndCustomerId(
                            data.paymentIntent.client_secret,
                            data.customer
                        )
                        handleLoading(false)
                    })
                })
                .catch((error) => {
                    error.json().then((e) => {
                        dispatchError({
                            type: ERROR_ACTIONS.SET_ERROR,
                            payload: {
                                id: 'emailError',
                                message: e.error,
                            },
                        })
                    })
                    handleLoading(false)
                })
        }
    }

    const sendForm = () => {
        if (!checkErrors()) {
            handleLoading(true)
            const data = {
                firstname: sales.firstname,
                lastname: sales.lastname,
                phone: sales.phone,
                email: sales.email,
                address: {
                    city: address.city,
                    street: address.street,
                    postalCode: address.zipCode,
                },
                siren: sales.siren,
                societyName: sales.societyName,
                nbVehicles: sales.nbVehicles,
                priceId: sales.product.priceId,
                customerId: sales.customerId,
            }
            API.Stripe.CreateContratAndAccount(data)
                .then((response) => {
                    response.json().then((data) => {
                        handleSignUpDone(true)
                        toast.success('Inscription réalisée avec succès.')
                    })
                })
                .catch((error) => {
                    error.json().then((e) => {
                        dispatchError({
                            type: ERROR_ACTIONS.SET_ERROR,
                            payload: {
                                id: 'emailError',
                                message: e.error,
                            },
                        })
                    })
                    handleLoading(false)
                })
        }
    }

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer')
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    return (
        <div className="invisible-scrollbar bg-color-light-gray h-screen overflow-y-auto">
            <div className="bg-light-dark-green pl-6">
                <div className="flex items-center py-4">
                    <div className="sm:pl-20">
                        <div className="hidden lg:block">
                            <Heroad />
                        </div>
                        <div className="block lg:hidden ">
                            <HeroadMobile />
                        </div>
                        <h2 className="pt-4 text-xs uppercase text-white lg:text-sm">
                            Votre régulation connectée
                        </h2>
                    </div>
                    <div className="logo-svg">
                        <div className="hidden lg:block">
                            <LogoIcon />
                        </div>
                        <div className="block lg:hidden ">
                            <LogoMobileIcon />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="mx-auto w-full max-w-4xl">
                    <div className="mt-10 grid grid-cols-1 rounded-md bg-white p-4 shadow-md md:grid-cols-2">
                        <PersonalInformations
                            sales={sales}
                            dispatch={dispatch}
                        />
                        <SocietyInformations
                            sales={sales}
                            dispatch={dispatch}
                        />
                        <div className="col-span-2 w-full px-6">
                            <GeoapifyAutocomplete
                                address={address}
                                dispatch={addressDispatch}
                                label={'Adresse de facturation'}
                            />
                        </div>
                        <div className="col-span-1 mx-auto flex w-full max-w-md flex-col space-y-4 px-6 pb-5">
                            <div className="py-5 text-2xl font-bold">Plan</div>
                            <div className="w-full">
                                <label
                                    className="mb-2 block text-sm font-bold text-gray-700"
                                    htmlFor="email"
                                >
                                    Choix du plan
                                </label>
                                <SelectDropDownList
                                    icon=""
                                    options={sales.products}
                                    value={sales.product}
                                    id="1"
                                    handleOptionChange={(e) => handleProduct(e)}
                                    isDisabled={false}
                                    showLabel={false}
                                    isSearchable={false}
                                />
                            </div>
                        </div>
                        <div className="col-span-1 mx-auto flex w-full max-w-md flex-col space-y-4 px-6 pb-5">
                            <div className="py-5 text-2xl font-bold">
                                {!sales.sepaFilledOut && 'Paiement'}
                            </div>
                            {!sales.stripeClientSecretKey ? (
                                <div className="flex items-center justify-center">
                                    <div className="w-2/3 pt-2">
                                        <button
                                            className="bg-color-light-dark-green transition-color flex w-full justify-center rounded-lg px-4 py-2 text-center font-bold text-white duration-200 hover:bg-dark-green focus:border-lighter-blue-green focus:outline-none focus:ring"
                                            type="button"
                                            onClick={
                                                createCustomerAndPaymentIntent
                                            }
                                        >
                                            {sales.loading ? (
                                                <InlineButtonLoader />
                                            ) : (
                                                'Accéder au paiement'
                                            )}
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                !sales.sepaFilledOut && (
                                    <Payment
                                        sales={sales}
                                        dispatch={dispatch}
                                    />
                                )
                            )}
                        </div>
                        <div className="col-span-2 mx-auto flex w-full max-w-lg flex-col px-6 pb-5 pt-10">
                            {!sales.signUpDone && sales.sepaFilledOut && (
                                <div>
                                    <h3 className="pb-6 text-xl font-bold">
                                        Informations de paiement bien
                                        renseignées !
                                    </h3>
                                    <button
                                        className="bg-color-light-dark-green transition-color flex w-full justify-center rounded-lg px-4 py-2 text-center font-bold text-white duration-200 hover:bg-dark-green focus:border-lighter-blue-green focus:outline-none focus:ring"
                                        type="button"
                                        onClick={sendForm}
                                    >
                                        {sales.loading ? (
                                            <InlineButtonLoader />
                                        ) : (
                                            "Valider l'inscription"
                                        )}
                                    </button>
                                </div>
                            )}
                            {sales.signUpDone && (
                                <h3 className="pb-6 text-xl font-bold">
                                    Inscription réalisée avec succès !
                                </h3>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed right-0 top-0 z-50 mr-5 flex flex flex-col space-y-2 text-gray-700">
                {errors.map((error) => {
                    return (
                        <Error
                            key={error.id}
                            error={error}
                            dispatch={dispatchError}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default SalesContainer
