import React from 'react'
import LineInput from './LineInput'
import { INVOICE_ACTIONS } from '../../../services/Reducers/InvoiceReducer'
const formatEuro = (number) => {
    return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
    }).format(number)
}

export const formatPrice = (number, field) => {
    if (number === '') {
        return 0
    }
    return number.match(/^[\d.]+$/) ? number : field
}

export const InvoiceLine = ({ line, dispatch, index, calculTTC }) => {
    return (
        <div
            key={line.id}
            className="flex w-full flex-wrap items-center space-y-2 lg:flex-nowrap lg:space-x-5"
        >
            <div className="w-full grow">
                <LineInput
                    label={'Libellé'}
                    value={line.label}
                    onChange={(e) => {
                        dispatch({
                            type: INVOICE_ACTIONS.UPDATE_LINE,
                            payload: {
                                ...line,
                                label: e.target.value,
                            },
                        })
                    }}
                    className={'px-5'}
                />
            </div>
            <div className="w-vat pr-2 lg:p-0">
                <LineInput
                    label={'TVA %'}
                    value={line.vat}
                    onChange={(e) => {
                        dispatch({
                            type: INVOICE_ACTIONS.UPDATE_LINE,
                            payload: {
                                ...line,
                                vat: formatPrice(e.target.value, line.vat),
                            },
                        })
                    }}
                    className="px-2 text-right"
                />
            </div>
            <div className="w-vat ml-auto pl-2 lg:m-0 lg:p-0">
                <LineInput
                    label={'QTE'}
                    value={line.quantity}
                    onChange={(e) => {
                        dispatch({
                            type: INVOICE_ACTIONS.UPDATE_LINE,
                            payload: {
                                ...line,
                                quantity: formatPrice(
                                    e.target.value,
                                    line.quantity
                                ),
                            },
                        })
                    }}
                    className="px-2 text-right"
                />
            </div>
            <div className="w-prices pr-2 lg:p-0">
                <LineInput
                    label={'PU TTC'}
                    value={line.unitPrice}
                    onChange={(e) => {
                        dispatch({
                            type: INVOICE_ACTIONS.UPDATE_LINE,
                            payload: {
                                ...line,
                                unitPrice: formatPrice(
                                    e.target.value,
                                    line.unitPrice
                                ),
                            },
                        })
                    }}
                    className="px-2 text-right"
                />
            </div>

            <div className="w-prices ml-auto pl-2 lg:m-0 lg:p-0">
                <LineInput
                    label={'Remise %'}
                    value={line.discount}
                    onChange={(e) => {
                        dispatch({
                            type: INVOICE_ACTIONS.UPDATE_LINE,
                            payload: {
                                ...line,
                                discount: formatPrice(
                                    e.target.value,
                                    line.discount
                                ),
                            },
                        })
                    }}
                    className="px-2 text-right"
                />
            </div>
            <div className=" w-mtt">
                <LineInput
                    label={'TTC'}
                    value={formatEuro(
                        calculTTC(line.quantity, line.unitPrice, line.discount)
                    )}
                    disabled={true}
                    className="px-2 text-right"
                />
            </div>
            <div
                className={`flex w-full justify-end pt-2 lg:hidden ${
                    line.id === 'Invoice_Line_1' && 'invisible'
                } `}
            >
                <button
                    className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700"
                    onClick={() =>
                        dispatch({
                            type: INVOICE_ACTIONS.DELETE_LINE,
                            payload: {
                                id: line.id,
                            },
                        })
                    }
                >
                    Supprimer
                </button>
            </div>
            <i
                onClick={() => {
                    dispatch({
                        type: INVOICE_ACTIONS.DELETE_LINE,
                        payload: {
                            id: line.id,
                        },
                    })
                }}
                className={`fas fa-minus-circle hidden cursor-pointer pt-5 text-red-600 lg:block  ${
                    index === 0 && 'invisible'
                } `}
            />
        </div>
    )
}
