import React, { useEffect, useLayoutEffect, useReducer, useRef } from 'react'
import {
    getInitialState,
    RUNINFO_ACTIONS,
    RunInformationReducer,
} from '../../services/Reducers/RunInformationReducer'
import { useNavigate, useParams } from 'react-router-dom'
import FetchRunsInformations from '../../services/fetchRunsInformations'
import dayjs from 'dayjs'
import Loader from '../Commons/Loaders/Loader'
import API from '../../services/api'
import { ValidationModal } from './ValidationModal'
import { ContactModal } from './ContactModal'
import { toast } from 'react-toastify'
import PrimaryButton from '../Commons/Buttons/PrimaryButton'
import Gleap from 'gleap'
import TransportInfo from './Cards/TransportInfo'
import PatientInfo from './Cards/PatientInfo'
import OptionForm from './Cards/OptionForm'
import SignatureForm from './Cards/SignatureForm'
import ModalSkeleton from '../Modal/ModalSkeleton'
import { useMutation } from '@tanstack/react-query'
import { Signature } from '../../services/API/Entities/signature'
import { formatDateToApi } from '../../services/dateUtils'
const RunInformation = () => {
    const ref = useRef(null)
    const signatureRef = useRef(null)
    const param = useParams()
    const history = useNavigate()

    const unlimitedSms = localStorage.getItem('unlimitedSms') === 'true'
    const isUser = localStorage.getItem('role') === 'USER'
    // on cache gleap si l'utilisateur est un chauffeur
    if (isUser) {
        Gleap.hide()
    }

    const [runInfo, dispatch] = useReducer(
        RunInformationReducer,
        undefined,
        getInitialState
    )

    const { mutate: saveSignature, isPending: isPendingSignature } =
        useMutation({
            mutationFn: Signature.saveSignature,
            onSuccess: (data) => {
                dispatch({
                    type: RUNINFO_ACTIONS.SET_SIGNATURE_ID,
                    payload: data.id,
                })
                handleCloseSignatureModal()
                toast.success('Signature enregistrée')
            },
            onError: () => {
                handleCloseSignatureModal()
            },
        })

    const fetchInformations = () => {
        FetchRunsInformations.getDriverRunInfo(
            param.id,
            dispatch,
            runInfo,
            RUNINFO_ACTIONS
        )
    }
    const changeRunStatus = () => {
        const newStatus = `/statuses/${
            runInfo.status.id === 'done' ? 'todo' : 'done'
        }`
        const data = {
            status: newStatus,
        }
        dispatch({
            type: RUNINFO_ACTIONS.SET_LOADING,
            payload: true,
        })
        API.Runs.put(param.id, data).then((data) => {
            dispatch({
                type: RUNINFO_ACTIONS.SET_STATUS,
                payload: data.status,
            })
            toast.success('Transport invalidé.')
        })
    }
    const pickUpRef = useRef(null)
    const depositRef = useRef(null)

    const handleCopyClick = (ref, pickUp = true) => {
        let msg = ''
        if (pickUp) {
            msg = 'Addresse de départ'
        } else {
            msg = "Addresse d'arrivée"
        }
        ref.current.select()
        document.execCommand('copy')
        toast.success(`${msg} copiée 🌟`)
    }

    useLayoutEffect(() => {
        if (!runInfo.loading) {
            dispatch({
                type: RUNINFO_ACTIONS.SET_WIDTH,
                payload: ref.current.offsetWidth,
            })
        }
    }, [ref, runInfo.loading])

    useEffect(() => {
        fetchInformations()
    }, [])

    const handleForm = (status = 'ongoing', notify = true) => {
        let message =
            status === 'done'
                ? 'Transport finalisé'
                : 'Prise en charge confirmée.'

        dispatch({
            type: RUNINFO_ACTIONS.SET_LOADING,
            payload: true,
        })
        let data = {
            status: `/statuses/${status}`,
            personToContact: runInfo.personToContact,
            patient: runInfo.patient['@id'],
            documents: runInfo.documents['@id'],
            amount: runInfo.amount > 0 ? parseFloat(runInfo.amount) : null,
            userComments: runInfo.userComments,
            runObject: runInfo.runObject['@id'],
            notify: notify,
        }
        // Cas de la prise en charge, on set l'effectiveDepartureTime à l'heure actuel
        if (status === 'ongoing') {
            data = {
                ...data,
                effectiveDepartureTime: formatDateToApi(
                    runInfo.effectiveDepartureTime
                ),
            }
        } else {
            data = {
                ...data,
                effectiveDepartureTime: formatDateToApi(
                    runInfo.effectiveDepartureTime
                ),
                effectiveArrivingTime: formatDateToApi(
                    runInfo.effectiveArrivingTime
                ),
            }
        }
        API.Runs.notifyContacts(param.id, data).then((response) => {
            response.json().then((data) => {
                dispatch({
                    type: RUNINFO_ACTIONS.SET_LOADING,
                    payload: false,
                })
                toast.success(message)
                if (status === 'done') {
                    history(-1)
                } else {
                    fetchInformations()
                }
            })
        })
    }

    const onScroll = (() => {
        let ticking = false
        let lastScrollLeft = 0
        return (e) => {
            if (!ticking) {
                window.requestAnimationFrame(function () {
                    let documentScrollLeft = e.target.scrollLeft
                    if (lastScrollLeft !== documentScrollLeft) {
                        dispatch({
                            type: RUNINFO_ACTIONS.SET_NAVIGATION_SCROLL,
                            payload: {
                                documentScrollLeft,
                                lastScrollLeft,
                            },
                        })
                        lastScrollLeft = documentScrollLeft
                    }
                    ticking = false
                })
                ticking = true
            }
        }
    })()

    const isEffectiveArrivingTimeBeforeEffectiveDepartureTime = () => {
        if (runInfo.effectiveArrivingTime && runInfo.effectiveDepartureTime) {
            const arrivingTime = dayjs(runInfo.effectiveArrivingTime).startOf(
                'minute'
            )
            const departureTime = dayjs(runInfo.effectiveDepartureTime).startOf(
                'minute'
            )

            if (arrivingTime <= departureTime) {
                return true
            }
        }

        return false
    }

    const handleValidate = () => {
        const errors = []

        if (signatureRef.current.isEmpty()) {
            errors.push('Veuillez fournir une signature')
        }
        if (!runInfo.signatureDate) {
            errors.push('Veuillez renseigner une date')
        }
        if (!runInfo.signatureLocation) {
            errors.push('Veuillez renseigner un lieu')
        }

        if (errors.length > 0) {
            errors.forEach((error) => toast.error(error))
            return // Sortir de la fonction si des erreurs sont présentes
        }

        const signatureData = signatureRef.current.toDataURL()

        dispatch({
            type: RUNINFO_ACTIONS.SET_SIGNATURE,
            payload: signatureData,
        })

        // Envoyer la signature au serveur
        const signature = {
            runId: param.id,
            data: {
                image: signatureData,
                date: dayjs(runInfo.signatureDate, 'DD/MM/YYYY').format(
                    'YYYY-MM-DD'
                ),
                location: runInfo.signatureLocation,
                signatureId: runInfo.signatureId,
            },
        }

        saveSignature(signature)
    }

    const handleCloseSignatureModal = () => {
        dispatch({
            type: RUNINFO_ACTIONS.SHOW_SIGNATURE_FORM,
            payload: false,
        })
    }

    return (
        <div className="mx-auto h-full w-full text-2xl text-gray-700">
            {runInfo.loading ? (
                <Loader />
            ) : (
                <div className="h-full ">
                    <div className="py-4 text-center text-sm">
                        <i
                            className={`fa fa-circle fa-lg ${runInfo.runInfoColor} pr-2`}
                        />
                        <i
                            className={`fa fa-circle fa-lg ${runInfo.patientInfoColor} pr-2`}
                        />
                        <i
                            className={`fa fa-circle fa-lg ${runInfo.optionColor} pr-2`}
                        />
                    </div>
                    {runInfo.status.id === 'canceled' && (
                        <div className="flex justify-center pb-4">
                            <p className="flex items-center font-bold text-orange-500">
                                <i className="fas fa-exclamation-circle pr-2 " />
                                <p>Transport annulé</p>
                            </p>
                        </div>
                    )}

                    <div
                        onScroll={onScroll}
                        ref={ref}
                        className="hide-scroll mx-auto flex w-full snap-x snap-mandatory overflow-scroll"
                    >
                        <TransportInfo
                            runInfo={runInfo}
                            unlimitedSms={unlimitedSms}
                            pickUpRef={pickUpRef}
                            depositRef={depositRef}
                            dispatch={dispatch}
                            handleForm={handleForm}
                            handleCopyClick={handleCopyClick}
                        />
                        <PatientInfo runInfo={runInfo} />
                        <OptionForm
                            runInfo={runInfo}
                            dispatch={dispatch}
                            changeRunStatus={changeRunStatus}
                            isEffectiveArrivingTimeBeforeEffectiveDepartureTime={
                                isEffectiveArrivingTimeBeforeEffectiveDepartureTime
                            }
                        />
                    </div>
                    {runInfo.status.id === 'todo' ||
                    runInfo.status.id === 'ongoing' ? (
                        <div className="flex w-full justify-center px-5 pb-20 pt-5">
                            <PrimaryButton
                                label="Valider le transport"
                                title="Valider le transport"
                                action={() =>
                                    dispatch({
                                        type: RUNINFO_ACTIONS.SHOW_MODAL,
                                    })
                                }
                                mobileSize={true}
                            />
                        </div>
                    ) : runInfo.hasChanged &&
                      runInfo.status.id !== 'canceled' ? (
                        <div className="w-full px-5 py-5">
                            <PrimaryButton
                                label="Modifier les informations"
                                title="Modifier les informations"
                                action={() => handleForm('done')}
                                mobileSize={true}
                            />
                        </div>
                    ) : null}
                    {runInfo.showModal && (
                        <ValidationModal
                            runInfo={runInfo}
                            dispatch={dispatch}
                            unlimitedSms={unlimitedSms}
                            handleForm={handleForm}
                            isEffectiveArrivingTimeBeforeEffectiveDepartureTime={
                                isEffectiveArrivingTimeBeforeEffectiveDepartureTime
                            }
                        />
                    )}
                    {runInfo.showSignatureForm && (
                        <ModalSkeleton
                            Component={SignatureForm}
                            label={'Signature du patient'}
                            handleCloseModal={handleCloseSignatureModal}
                            componentProps={{
                                signatureRef: signatureRef,
                                dispatch: dispatch,
                                location: runInfo.signatureLocation,
                                date: runInfo.signatureDate,
                                signature: runInfo.signature,
                                signatureId: runInfo.signatureId,
                            }}
                            handleForm={handleValidate}
                            buttonLabel={'Confirmer la signature'}
                            loader={isPendingSignature}
                            disabledSubmit={isPendingSignature}
                        />
                    )}
                    {runInfo.showContactModal && (
                        <ContactModal
                            runInfo={runInfo}
                            dispatch={dispatch}
                            handleForm={handleForm}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default RunInformation
