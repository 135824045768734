import { TarifList } from '../../../../services/TarifList'
import { ABATEMENT } from '../../../../services/InvoiceUtils'
import { formatDateFromApiToDateObject } from '../../../../services/dateUtils'

export const INVOICE_CPAM_ACTIONS = {
    SET_COMPLEMENTARY_PART: 'SET_COMPLEMENTARY_PART',
    SET_SUPPLEMENT: 'SET_SUPPLEMENT',
    SET_SUPPLEMENT_DETAILS: 'SET_SUPPLEMENT_DETAILS',
    SET_SHOW_INVOICE_MODAL_FORM: 'SET_SHOW_INVOICE_MODAL_FORM',
    FETCH_INVOICE_CPAM: 'FETCH_INVOICE_CPAM',
    ADD_TARIF_LINE: 'ADD_TARIF_LINE',
    UPDATE_TARIF_LINE: 'UPDATE_TARIF_LINE',
    ADD_SUPPLEMENT_LINE: 'ADD_SUPPLEMENT_LINE',
    UPDATE_SUPPLEMENT_LINE: 'UPDATE_SUPPLEMENT_LINE',
    REMOVE_SUPPLEMENT_LINE: 'REMOVE_SUPPLEMENT_LINE',
    REMOVE_TARIF_LINE: 'REMOVE_TARIF_LINE',
    SET_TARIFS_LINES: 'SET_tarifs_LINES',
    SET_PRICE_DETAILS: 'SET_PRICE_DETAILS',
    UPDATE_TARIF_A_KM: 'UPDATE_TARIF_A_KM',
    UPDATE_TARIF_B_KM: 'UPDATE_TARIF_B_KM',
    UPDATE_TARIF_C_KM: 'UPDATE_TARIF_C_KM',
    UPDATE_TARIF_D_KM: 'UPDATE_TARIF_D_KM',
    OPEN_ACTION_MENU: 'OPEN_ACTION_MENU',
    OPEN_AMO_STATUS_LIST: 'OPEN_AMO_STATUS_LIST',
    OPEN_AMC_STATUS_LIST: 'OPEN_AMC_STATUS_LIST',
    SET_SHARED_RUN: 'SET_SHARED_RUN',
    SET_ABATEMENT: 'SET_ABATEMENT',
    SET_NB_PEOPLE: 'SET_NB_PEOPLE',
    SET_SHOW_REJECT_HISTORY: 'SET_SHOW_REJECT_HISTORY',
    ADD_WAITING_SUPPLEMENT_LINE: 'ADD_WAITING_SUPPLEMENT_LINE',
    SET_FORFAIT: 'SET_FORFAIT',
    SET_FORFAIT_AMOUNT: 'SET_FORFAIT_AMOUNT',
    UPDATE_RUN_INVOICE_DETAILS: 'UPDATE_RUN_INVOICE_DETAILS',
    UPDATE_ALL_RUNS_INVOICE_DETAILS: 'UPDATE_ALL_RUNS_INVOICE_DETAILS',
    SET_TOTALS: 'SET_TOTALS',
    UPDATE_KM_RUN_INVOICE_DETAILS: 'UPDATE_KM_RUN_INVOICE_DETAILS',
    UPDATE_FORM_RUN_INVOICE_DETAILS: 'UPDATE_FORM_RUN_INVOICE_DETAILS',
    UPDATE_AMOUNT_RUN_INVOICE_DETAILS: 'UPDATE_AMOUNT_RUN_INVOICE_DETAILS',
    UPDATE_FORM_PRICE_BASIS: 'UPDATE_FORM_PRICE_BASIS',
    INIT_PRICE_BASIS: 'INIT_PRICE_BASIS',
    UPDATE_FORM_HOLDER: 'UPDATE_FORM_HOLDER',
    UPDATE_PRICE_BASIS: 'UPDATE_PRICE_BASIS',
    SET_DATE: 'SET_DATE',
    SHOW_CHANGE_STATUS_DATE_MODAL: 'SHOW_CHANGE_STATUS_DATE_MODAL',
    SET_IS_MINIMUM_PERCEPTION: 'SET_IS_MINIMUM_PERCEPTION',
    SET_MINIMUM_PERCEPTION_AMOUNT: 'SET_MINIMUM_PERCEPTION_AMOUNT',
}

export const getInvoiceFormInitialState = ({ invoiceCpamForm }) => {
    if (invoiceCpamForm) {
        const tarif = invoiceCpamForm.totalsTarifTypes.totalsTarifTypes
        return {
            ...invoiceCpamForm,
            tarifA: tarif['A'] ? tarif['A']?.total : null,
            kilometerA: tarif['A'] ? parseFloat(tarif['A']?.kilometer) : null,
            kilometerC: tarif['C'] ? parseFloat(tarif['C']?.kilometer) : null,
            kilometerB: tarif['B'] ? parseFloat(tarif['B']?.kilometer) : null,
            kilometerD: tarif['D'] ? parseFloat(tarif['D']?.kilometer) : null,
            tarifB: tarif['B'] ? tarif['B']?.total : null,
            tarifC: tarif['C'] ? tarif['C']?.total : null,
            tarifD: tarif['D'] ? tarif['D']?.total : null,
            invoiceSupplements:
                invoiceCpamForm.totalRunPrices.invoiceSupplements,
        }
    } else {
        return {
            id: null,
            supplement: '',
            invoiceSupplements: [],
            tarifs: [],
            date: new Date(),
            priceDetails: [],
            supplementDetails: '',
            complementaryPart: '',
            showInvoiceModalForm: false,
            tarifList: TarifList,
            tarifA: null,
            tarifB: null,
            tarifC: null,
            tarifD: null,
            kilometerA: null,
            kilometerB: null,
            kilometerC: null,
            kilometerD: null,
            openActionMenu: false,
            openAMOStatusList: false,
            openAMCStatusList: false,
            sharedRun: false,
            abatement: null,
            nbPeople: 1,
            isForfait: false,
            forfaitAmount: null,
            runsInvoiceFormsDetails: [],
            totals: {},
            runsInvoiceFormsDetailsHolder: [],
            priceBasis: {},
            priceBasisForm: {},
            runs: [],
            showStatusChangeDateModal: false,
        }
    }
}

export const InvoiceCpamReducer = (state, action) => {
    switch (action.type) {
        case INVOICE_CPAM_ACTIONS.FETCH_INVOICE_CPAM: {
            return {
                ...state,
                ...action.payload,
                sharedRun: action.payload?.nbPeople > 1,
                abatement:
                    action.payload?.abatement && action.payload?.nbPeople > 1
                        ? ABATEMENT.find(
                              (item) => item.value === action.payload.abatement
                          )
                        : ABATEMENT[0],
                date: formatDateFromApiToDateObject(action.payload.date, true),
            }
        }
        case INVOICE_CPAM_ACTIONS.OPEN_ACTION_MENU: {
            return {
                ...state,
                openActionMenu: action.payload,
            }
        }
        case INVOICE_CPAM_ACTIONS.OPEN_AMO_STATUS_LIST: {
            return {
                ...state,
                openAMOStatusList: action.payload,
            }
        }
        case INVOICE_CPAM_ACTIONS.OPEN_AMC_STATUS_LIST: {
            return {
                ...state,
                openAMCStatusList: action.payload,
            }
        }
        case INVOICE_CPAM_ACTIONS.SET_TARIFS_LINES: {
            let kilometerA = 0
            let kilometerB = 0
            let kilometerC = 0
            let kilometerD = 0
            action?.payload?.forEach((item) => {
                switch (item.tarifType) {
                    case 'A':
                        kilometerA = item.km
                        break
                    case 'B':
                        kilometerB = item.km
                        break
                    case 'C':
                        kilometerC = item.km
                        break
                    case 'D':
                        kilometerD = item.km
                        break
                    default:
                        break
                }
            })
            return {
                ...state,
                kilometerA: kilometerA,
                kilometerB: kilometerB,
                kilometerC: kilometerC,
                kilometerD: kilometerD,
            }
        }
        case INVOICE_CPAM_ACTIONS.SET_PRICE_DETAILS:
            return {
                ...state,
                priceDetails: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.SET_COMPLEMENTARY_PART:
            return {
                ...state,
                complementaryPart: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.SET_SUPPLEMENT:
            return {
                ...state,
                supplement: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.SET_SUPPLEMENT_DETAILS:
            return {
                ...state,
                supplementDetails: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.SET_SHOW_INVOICE_MODAL_FORM:
            return {
                ...state,
                showInvoiceModalForm: !state.showInvoiceModalForm,
            }
        case INVOICE_CPAM_ACTIONS.ADD_TARIF_LINE:
            return {
                ...state,
                tarifs: [
                    ...state.tarifs,
                    newTarifLine(state.tarifs.length + 1, action.payload),
                ],
            }
        case INVOICE_CPAM_ACTIONS.UPDATE_TARIF_LINE:
            return {
                ...state,
                tarifs: [
                    ...state.tarifs.map((line) => {
                        if (line.id === action.payload.id) {
                            return {
                                ...action.payload,
                            }
                        } else {
                            return line
                        }
                    }),
                ],
            }
        case INVOICE_CPAM_ACTIONS.ADD_SUPPLEMENT_LINE:
            return {
                ...state,
                invoiceSupplements: [
                    ...state.invoiceSupplements,
                    newSupplementLine(
                        state.invoiceSupplements.length + 1,
                        action.payload
                    ),
                ],
            }
        case INVOICE_CPAM_ACTIONS.UPDATE_SUPPLEMENT_LINE:
            return {
                ...state,
                invoiceSupplements: [
                    ...state.invoiceSupplements.map((line) => {
                        if (line.id === action.payload.id) {
                            return {
                                ...action.payload,
                            }
                        } else {
                            return line
                        }
                    }),
                ],
            }
        case INVOICE_CPAM_ACTIONS.REMOVE_SUPPLEMENT_LINE:
            return {
                ...state,
                invoiceSupplements: [
                    ...state.invoiceSupplements.filter(
                        (line) => line.id !== action.payload
                    ),
                ],
            }
        case INVOICE_CPAM_ACTIONS.REMOVE_TARIF_LINE:
            return {
                ...state,
                tarifs: [
                    ...state.tarifs.filter(
                        (line) => line.id !== action.payload
                    ),
                ],
            }
        case INVOICE_CPAM_ACTIONS.UPDATE_TARIF_A_KM:
            return {
                ...state,
                kilometerA: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.UPDATE_TARIF_B_KM:
            return {
                ...state,
                kilometerB: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.UPDATE_TARIF_C_KM:
            return {
                ...state,
                kilometerC: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.UPDATE_TARIF_D_KM:
            return {
                ...state,
                kilometerD: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.SET_SHARED_RUN:
            return {
                ...state,
                sharedRun: action.payload,
                nbPeople: 2,
                abatement: ABATEMENT[0],
            }
        case INVOICE_CPAM_ACTIONS.SET_ABATEMENT:
            return {
                ...state,
                abatement: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.SET_NB_PEOPLE:
            return {
                ...state,
                nbPeople: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.SET_SHOW_REJECT_HISTORY:
            return {
                ...state,
                showRejectHistory: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.ADD_WAITING_SUPPLEMENT_LINE:
            return {
                ...state,
                invoiceSupplements: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.SET_FORFAIT:
            return {
                ...state,
                isForfait: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.SET_FORFAIT_AMOUNT:
            return {
                ...state,
                forfaitAmount: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.UPDATE_RUN_INVOICE_DETAILS:
            return {
                ...state,
                runsInvoiceFormsDetails: [
                    ...state.runsInvoiceFormsDetails?.map(
                        (runInvoiceDetails) => {
                            console.log(runInvoiceDetails.runId)
                            if (
                                runInvoiceDetails?.runId ===
                                action.payload.runId
                            ) {
                                return action.payload
                            } else {
                                return runInvoiceDetails
                            }
                        }
                    ),
                ],
            }
        case INVOICE_CPAM_ACTIONS.UPDATE_KM_RUN_INVOICE_DETAILS:
            return {
                ...state,
                runsInvoiceFormsDetails: [
                    ...state.runsInvoiceFormsDetails?.map(
                        (runInvoiceDetails) => {
                            if (
                                runInvoiceDetails?.runId ===
                                parseInt(action.payload.runId)
                            ) {
                                return {
                                    ...runInvoiceDetails,
                                    totalKilometer: parseFloat(
                                        action.payload.kilometer
                                    ),
                                }
                            } else {
                                return runInvoiceDetails
                            }
                        }
                    ),
                ],
            }
        case INVOICE_CPAM_ACTIONS.UPDATE_AMOUNT_RUN_INVOICE_DETAILS:
            return {
                ...state,
                runsInvoiceFormsDetails: [
                    ...state.runsInvoiceFormsDetails?.map(
                        (runInvoiceDetails) => {
                            if (
                                runInvoiceDetails?.runId ===
                                parseInt(action.payload.runId)
                            ) {
                                return {
                                    ...runInvoiceDetails,
                                    amount: parseFloat(action.payload.amount),
                                }
                            } else {
                                return runInvoiceDetails
                            }
                        }
                    ),
                ],
            }
        case INVOICE_CPAM_ACTIONS.UPDATE_FORM_RUN_INVOICE_DETAILS:
            return {
                ...state,
                runsInvoiceFormsDetailsHolder: [
                    ...state.runsInvoiceFormsDetailsHolder?.map(
                        (runInvoiceDetails) => {
                            if (
                                runInvoiceDetails?.runId ===
                                action.payload.runId
                            ) {
                                return action.payload
                            } else {
                                return runInvoiceDetails
                            }
                        }
                    ),
                ],
            }
        case INVOICE_CPAM_ACTIONS.SET_TOTALS:
            return {
                ...state,
                totals: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.UPDATE_ALL_RUNS_INVOICE_DETAILS:
            return {
                ...state,
                runsInvoiceFormsDetails: action.payload,
                runsInvoiceFormsDetailsHolder: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.UPDATE_FORM_PRICE_BASIS:
            return {
                ...state,
                runsInvoiceFormsDetailsHolder:
                    state.runsInvoiceFormsDetailsHolder.map((run) => {
                        return {
                            ...run,
                            priceBasis: action.payload,
                        }
                    }),
                priceBasisForm: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.INIT_PRICE_BASIS:
            return {
                ...state,
                runsInvoiceFormsDetailsHolder:
                    state.runsInvoiceFormsDetailsHolder.map((run) => {
                        return {
                            ...run,
                            priceBasis: action.payload,
                        }
                    }),
                priceBasis: action.payload,
                priceBasisForm: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.UPDATE_FORM_HOLDER:
            return {
                ...state,
                runsInvoiceFormsDetailsHolder: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.UPDATE_PRICE_BASIS:
            return {
                ...state,
                priceBasis: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.SET_DATE:
            return {
                ...state,
                date: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.SHOW_CHANGE_STATUS_DATE_MODAL:
            return {
                ...state,
                showStatusChangeDateModal: !state.showStatusChangeDateModal,
            }
        default:
            return state
    }
}

const newTarifLine = (id, tarif) => {
    return {
        id: id,
        tarifType: tarif,
        kilometer: '',
    }
}

const newSupplementLine = (id) => {
    return {
        ['@id']: id,
        label: '',
        price: '',
        isDiscounted: false,
    }
}
