import React from 'react'
import API from '../../../../services/api'
import SelectDropDownListV2 from '../../../Commons/DropDownLists/SelectDropDownListV2'
import { RunStatus } from '../../../../services/RunStatus'
import Loader from '../../../Commons/Loaders/Loader'
import { TRANSPORT_ACTIONS } from '../Reducers/TransportFormReducer'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { Documents } from '../../../../services/Documents'
import ClassicTextarea from '../../../Commons/Inputs/ClassicTextarea'
import DeleteButton from '../../../Commons/Buttons/DeleteButton'
import { Clock, Trash } from 'iconoir-react'
import TimeField from 'react-simple-timefield'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import SelectStatus from '../../../Commons/DropDownLists/SelectStatus'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Run } from '../../../../services/API/Entities/run'
import { saveAs } from 'file-saver'
import { useNavigate } from 'react-router-dom'
import { RUNINFO_ACTIONS } from '../../../../services/Reducers/RunInformationReducer'
import { formatTimeInput } from '../../../../services/dateUtils'

const SettingsForm = ({ run, dispatch }) => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = React.useState(false)
    // add mutation for delete run
    const { mutate: handleDeleteRun, isPending: isDeletePending } = useMutation(
        {
            mutationFn: Run.deleteRun,
            onSuccess: () => {
                navigate(-1)
                toast.success('Transport supprimé')
            },
        }
    )

    const {
        refetch: fetchDownloadSignaturePdf,
        isLoading: isLoadingDownloadSignaturePdf,
    } = useQuery({
        queryKey: ['pdf_signature', run.id],
        queryFn: async () => {
            return await Run.downloadSignaturePdf({
                id: run.id,
                signatureId: run.signatureId,
                location: run.signatureLocation,
                date: dayjs(run.signatureDate, 'DD/MM/YYYY').format(
                    'YYYY-MM-DD'
                ),
            }) // Assurez-vous que c'est bien un blob
        },
        enabled: false,
    })

    const handleStatusChange = async (event) => {
        const newStatus = `/statuses/${event}`
        const data = { status: newStatus }
        setIsLoading(true)
        try {
            const response = await API.Runs.put(run.id, data)
            dispatch({
                type: TRANSPORT_ACTIONS.UPDATE_STATUS,
                payload: response.status.label,
            })
            toast.success('Statut mis à jour')
        } catch (error) {
            if (error.json) {
                const data = await error.json()
                toast.error(data['hydra:description'])
            } else {
                toast.error('Erreur, veuillez contacter un administrateur')
            }
        }
        setIsLoading(false)
    }

    const handleCommentsChange = (e) => {
        dispatch({
            type: TRANSPORT_ACTIONS.SET_USER_COMMENTS,
            payload: e.target.value,
        })
    }

    const handleNoteChange = (e) => {
        dispatch({
            type: TRANSPORT_ACTIONS.SET_NOTE,
            payload: e.target.value,
        })
    }

    const handleDocumentsChange = (event) => {
        dispatch({
            type: TRANSPORT_ACTIONS.SET_DOCUMENTS,
            payload: event,
        })
    }

    const handleDelete = () => {
        handleDeleteRun(run.id)
    }

    const handleDownloadSignature = async () => {
        const pdfSignature = await fetchDownloadSignaturePdf()
        if (pdfSignature.data instanceof Blob) {
            toast.success('Succès.')
            saveAs(
                pdfSignature.data,
                `annexe_4_${
                    run.patient.lastname + '_' + run.patient.firstname
                }.pdf`
            )
        } else {
            toast.error('Erreur lors de la génération du PDF')
        }
    }

    return (
        <div className="flex w-full flex-col space-y-4 pb-10">
            <div className="flex items-center justify-between pb-10 pt-6">
                <h1 className="text-2xl font-semibold">
                    Paramètres du transport
                </h1>
                <div className="w-48">
                    <SelectStatus
                        status={run.status}
                        handleStatusChange={handleStatusChange}
                        statusOptions={RunStatus}
                    />
                </div>
            </div>
            {isLoading ? (
                <Loader />
            ) : (
                <div className=" rounded-lg bg-white p-6 shadow-md">
                    <div className="space-y-6">
                        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                            <div>
                                <div className="rounded-md bg-gray-50 p-4 pb-8">
                                    <h2 className="mb-4 flex items-center text-lg font-semibold">
                                        <Clock className="mr-2" />
                                        Horaires effectifs
                                    </h2>
                                    <div className="flex">
                                        <div className="inline-block w-1/2 text-center">
                                            <label className="block text-base font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                                                Départ
                                            </label>
                                            <TimeField
                                                value={formatTimeInput(
                                                    run.effectiveDepartureTime
                                                )}
                                                onChange={(e) =>
                                                    dispatch({
                                                        type: TRANSPORT_ACTIONS.SET_EFFECTIVE_DEPARTURE_TIME,
                                                        payload: e.target.value,
                                                    })
                                                }
                                                input={
                                                    <input
                                                        className={`manage-max-w-hours h-10 w-full border-b-2 bg-transparent text-center leading-tight focus:outline-none ${
                                                            run.effectiveDepartureTimeError
                                                                ? 'border-red-500'
                                                                : 'border-gray-300 focus:border-lighter-blue-green'
                                                        }
                                               `}
                                                    />
                                                }
                                                colon=":"
                                            />
                                        </div>
                                        <div className="flex items-center justify-center px-3 pt-3 ">
                                            <i className="fa fa-arrow-right fa-lg " />
                                        </div>
                                        <div className="inline-block w-1/2 text-center">
                                            <label className="block text-base font-bold uppercase  tracking-wide text-gray-700 lg:text-xs">
                                                Arrivée
                                            </label>
                                            <TimeField
                                                value={formatTimeInput(
                                                    run.effectiveArrivingTime
                                                )}
                                                onChange={(e) =>
                                                    dispatch({
                                                        type: TRANSPORT_ACTIONS.SET_EFFECTIVE_ARRIVING_TIME,
                                                        payload: e.target.value,
                                                    })
                                                }
                                                input={
                                                    <input
                                                        className={`manage-max-w-hours h-10 w-full border-b-2 bg-transparent text-center leading-tight focus:outline-none ${
                                                            run.effetciveArrivingTimeError
                                                                ? 'border-red-500'
                                                                : 'border-gray-300 focus:border-lighter-blue-green'
                                                        }
                                               `}
                                                    />
                                                }
                                                colon=":"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {run.signature ? (
                                <div className={'flex flex-col'}>
                                    <div className="mb-2 flex items-center justify-between">
                                        <p className="mb-2 font-bold text-gray-600">
                                            Signature du patient
                                        </p>
                                        <SecondaryButton
                                            action={handleDownloadSignature}
                                            loader={
                                                isLoadingDownloadSignaturePdf
                                            }
                                            label="Télécharger annexe 4"
                                        />
                                    </div>
                                    <div className="flex flex-col items-center rounded border border-gray-300">
                                        <img
                                            src={run.signature}
                                            alt="Signature du patient"
                                            className="h-40 max-w-full "
                                        />
                                        <p className="mt-2 text-lg text-gray-600">
                                            Signé le {run.signatureDate} à{' '}
                                            {run.signatureLocation}
                                        </p>
                                    </div>
                                    <div className="mb-4 text-lg text-gray-600">
                                        <div className="flex flex-col space-y-4">
                                            <div className="flex items-baseline pt-4">
                                                <span className="mr-2">
                                                    Fait le
                                                </span>
                                                <input
                                                    type="text"
                                                    value={run.signatureDate}
                                                    placeholder={'jj/mm/aaaa'}
                                                    onChange={(e) =>
                                                        dispatch({
                                                            type: RUNINFO_ACTIONS.SET_SIGNATURE_DATE,
                                                            payload:
                                                                e.target.value,
                                                        })
                                                    }
                                                    className="flex-grow border-b border-secondary-color text-center font-bold focus:border-primary-color focus:outline-none"
                                                />
                                            </div>
                                            <div className="mt-2 flex items-baseline">
                                                <span className="mr-11">à</span>
                                                <input
                                                    type="text"
                                                    placeholder={'lieu'}
                                                    value={
                                                        run.signatureLocation
                                                    }
                                                    onChange={(e) =>
                                                        dispatch({
                                                            type: TRANSPORT_ACTIONS.SET_SIGNATURE_LOCATION,
                                                            payload:
                                                                e.target.value,
                                                        })
                                                    }
                                                    className="flex-grow border-b border-secondary-color text-center font-bold focus:border-primary-color focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            <div>
                                <SelectDropDownListV2
                                    label="Documents"
                                    isClearable={true}
                                    isSearchable={false}
                                    value={run.documents}
                                    options={Documents}
                                    handleOptionChange={handleDocumentsChange}
                                />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                            <ClassicTextarea
                                setValue={handleNoteChange}
                                value={run.note}
                                label="Note de régulation"
                            />
                            <ClassicTextarea
                                setValue={handleCommentsChange}
                                value={run.userComments}
                                label="Commentaire chauffeur"
                            />
                        </div>
                        <div className="mt-8 flex items-center justify-between">
                            <DeleteButton
                                icon={<Trash />}
                                action={handleDelete}
                                loader={isDeletePending}
                                label="Supprimer le transport"
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SettingsForm
