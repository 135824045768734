import { formatVehicleOptions } from '../../tools/Utility'
import * as dayjs from 'dayjs'
import RunTypes from '../RunTypes'
import {
    addMinutes,
    formatDateToApi,
    formatDateYYYYMMDD,
    removeTimeZoneToDate,
} from '../dateUtils'

export const SERIES_ACTIONS = {
    SET_LABEL: 'setLabel',
    SET_DATE: 'setDate',
    SET_PATIENT: 'setPatient',
    UPDATE_PATIENT: 'updatePatient',
    SET_PATIENT_SEARCH_INPUT: 'setPatientSearchInput',
    SHOW_PATIENT_MODAL: 'showPatientModal',
    SHOW_PATIENT: 'showPatient',
    SET_RUN_OBJECTS: 'setRunObjects',
    SET_RUN_OBJECT: 'setRunObject',
    SHOW_AMOUNT: 'showAmount',
    SET_AMOUNT: 'setAmount',
    SET_VEHICLE_TYPE: 'setVehicleType',
    SET_PARAMETERS: 'setParameters',
    SET_PRESCRIPTION_DATE: 'setPrescriptionDate',
    SET_KILOMETER: 'setKilometer',
    SET_PRESCRIPTION_NUMBER: 'setPrescriptionNumber',
    SET_COMMENTS: 'setComments',
    SET_IS_SETUP_SERIES_VIEW: 'setIsSetupSeriesView',
    SET_DISTANCE_NOT_FOUND: 'setDistanceNotFound',
    SET_BY_DATES_INTERVAL: 'setByDatesInterval',
    SET_OPEN_SELECT_START_DATE_PICKER: 'setOpenSelectStartDatePicker',
    SET_OPEN_SELECT_END_DATE_PICKER: 'setOpenSelectEndDatePicker',
    SET_START_DATE: 'setStartDate',
    SET_END_DATE: 'setEndDate',
    SET_NB_RUNS: 'setNbRuns',
    SET_FREQUENCY: 'setFrequency',
    SET_DAYS_OPTIONS: 'setDaysOptions',
    SET_DAY: 'setDay',
    ADD_DAY: 'addDay',
    REMOVE_DAY: 'removeDay',
    SET_DAY_DEPARTURE_TIME: 'setDayDeparturetime',
    SET_DAY_ARRIVING_TIME: 'setDayArrivingtime',
    SET_DAY_RUN_TYPE: 'setDayRunType',
    SET_DAY_RETURN_TIME: 'setDayReturnType',
    SET_DAY_WAITING_TIME: 'setDayWaitingTime',
    SET_DAY_IS_DIFFERENT_RETURN_DEPOSIT: 'setDayIsDifferentReturnDeposit',
    SET_DAY_COMMENTS: 'setDayComments',
    SET_RUNS_TO_INSERT: 'setRunsToInsert',
    SET_RUN_TO_INSERT_DATE: 'setRunToInsertDate',
    SET_RUN_TO_INSERT_DEPARTURE_TIME: 'setRunToInsertDepartureTime',
    SET_RUN_TO_INSERT_ARRIVING_TIME: 'setRunToInsertArrivingTime',
    SET_RUN_TO_INSERT_RETURN_TIME: 'setRunToInsertReturnTime',
    SET_RUN_TO_INSERT_WAITING_TIME: 'setRunToInsertWaitingTime',
    SET_RUN_TO_INSERT_TEAM: 'setRunToInsertTeam',
    DELETE_RUN_TO_INSERT: 'deleteRunToInsert',
    ADD_NEW_RUN_TO_INSERT: 'addNewRunToInsert',
    SET_SERIES: 'setSeries',
    SET_OPEN_ADD_MENU: 'setOpenAddMenu',
    SET_LOADING: 'setLoading',
    SET_OPEN_VALIDATION_MODAL: 'setOpenValidationModal',
    UPDATE_INVOICE: 'updateInvoice',
    SET_TEAMS: 'setTeams',
}

export const getInitialState = ({ date }) => {
    const dateForTimes = '1970-01-01T08:00:00'
    return {
        loading: true,
        newSeries: true,
        patients: [],
        runObjects: [],
        vehiclesTypes: [],
        patientSearchInput: '',
        patient: null,
        prsDate: formatDateYYYYMMDD(date),
        prsNumber: null,
        kilometer: null,
        distanceNotFound: false,
        runObject: {
            label: 'Consultation',
            value: 'Consultation',
            ['@id']: '/run_objects/Consultation',
        },
        globalRunInformations: {
            type: null,
            depositLocation: null,
            pickUpLocation: null,
            isGiven: null,
            amount: 0,
            showAmount: false,
        },
        comments: null,
        firstRunDate: date,
        byIntervalDates: true,
        lastRunDate: date,
        label: null,
        nbRuns: null,
        frequency: { value: 0, label: 'Toutes les semaines' },
        days: [
            {
                day: dayjs(date).weekday(),
                departureTime: removeTimeZoneToDate(dateForTimes),
                arrivingTime: formatDateToApi(
                    addMinutes(
                        new Date(removeTimeZoneToDate(dateForTimes)),
                        60
                    ),
                    true
                ),
                returnTime: removeTimeZoneToDate(dateForTimes),
                depositLocation: null,
                pickUpLocation: null,
                differentReturnDeposit: false,
                returnDepositLocation: null,
                comments: null,
                isReturnPath: false,
                waitingTime: 20,
                isRoundTrip: false,
                isDirectReturn: false,
                runType: RunTypes[0],
            },
        ],
        runsToInsert: [],
        patientIsDisabled: false,
        showPatientModal: false,
        isSetupSeriesView: false,
        openSelectStartDatePicker: false,
        openSelectEndDatePicker: false,
        daysOptions: [
            { value: 0, label: 'Lundi', enLabel: 'Monday' },
            { value: 1, label: 'Mardi', enLabel: 'Tuesday' },
            { value: 2, label: 'Mercredi', enLabel: 'Wednesday' },
            { value: 3, label: 'Jeudi', enLabel: 'Thursday' },
            { value: 4, label: 'Vendredi', enLabel: 'Friday' },
            { value: 5, label: 'Samedi', enLabel: 'Saturday' },
            { value: 6, label: 'Dimanche', enLabel: 'Sunday' },
        ].filter((d) => d.value !== dayjs(date).weekday()),
        daysDefaultList: [
            { value: 0, label: 'Lundi', enLabel: 'Monday' },
            { value: 1, label: 'Mardi', enLabel: 'Tuesday' },
            { value: 2, label: 'Mercredi', enLabel: 'Wednesday' },
            { value: 3, label: 'Jeudi', enLabel: 'Thursday' },
            { value: 4, label: 'Vendredi', enLabel: 'Friday' },
            { value: 5, label: 'Samedi', enLabel: 'Saturday' },
            { value: 6, label: 'Dimanche', enLabel: 'Sunday' },
        ],
        openAddMenu: false,
        openValidationModal: false,
        needsRefresh: false,
        cpamInvoices: [],
        teams: [],
    }
}

export const SeriesReducer = (state, action) => {
    switch (action.type) {
        case SERIES_ACTIONS.SET_DATE:
            return {
                ...state,
                date: action.payload,
            }
        case SERIES_ACTIONS.SET_LABEL:
            return {
                ...state,
                label: action.payload,
            }
        case SERIES_ACTIONS.SHOW_PATIENT: {
            return {
                ...state,
                showPatient: action.payload,
            }
        }
        case SERIES_ACTIONS.SET_PATIENT: {
            return {
                ...state,
                patient: action.payload ? action.payload.patient : null,
                globalRunInformations: {
                    ...state.globalRunInformations,
                    runObject: action.payload?.patient.defaultRunObject
                        ? action.payload.patient.defaultRunObject
                        : state.runObject,
                    vehicleType: action.payload?.patient.defaultType
                        ? action.payload.patient.defaultType
                        : state.vehicleType,
                },
            }
        }
        case SERIES_ACTIONS.SET_PATIENT_SEARCH_INPUT: {
            return {
                ...state,
                patients: action.payload,
            }
        }
        case SERIES_ACTIONS.SHOW_PATIENT_MODAL: {
            return {
                ...state,
                showPatientModal: action.payload,
            }
        }
        case SERIES_ACTIONS.UPDATE_PATIENT: {
            return {
                ...state,
                patient: action.payload,
            }
        }
        case SERIES_ACTIONS.SET_RUN_OBJECTS: {
            return {
                ...state,
                runObjects: action.payload,
            }
        }
        case SERIES_ACTIONS.SET_RUN_OBJECT: {
            return {
                ...state,
                runObject: action.payload,
                globalRunInformations: {
                    ...state.globalRunInformations,
                    showAmount: action.payload.label.includes('payant'),
                },
            }
        }
        case SERIES_ACTIONS.SHOW_AMOUNT: {
            return {
                ...state,
                globalRunInformations: {
                    ...state.globalRunInformations,
                    showAmount: action.payload,
                },
            }
        }
        case SERIES_ACTIONS.SET_AMOUNT: {
            return {
                ...state,
                globalRunInformations: {
                    ...state.globalRunInformations,
                    amount: action.payload.replace(/[^0-9.]+/g, ''),
                },
            }
        }
        case SERIES_ACTIONS.SET_VEHICLE_TYPE: {
            return {
                ...state,
                globalRunInformations: {
                    ...state.globalRunInformations,
                    type: action.payload,
                },
            }
        }
        case SERIES_ACTIONS.SET_PARAMETERS: {
            let runObject =
                action.payload.defaultVehicleType.label === 'VTC'
                    ? {
                          label: 'Course payante',
                          value: 'Course payante',
                          ['@id']: 'run_objects/Course payante',
                      }
                    : state.runObject
            return {
                ...state,
                globalRunInformations: {
                    ...state.globalRunInformations,
                    type: {
                        value: action.payload.defaultVehicleType.label,
                        label: action.payload.defaultVehicleType.label,
                        ['@id']: action.payload.defaultVehicleType['@id'],
                    },
                    runObject: runObject,
                },
                vehiclesTypes: formatVehicleOptions(
                    action.payload.defaultVehiclesTypes
                ),
                days: state.days.map((day, index) => {
                    if (index === 0) {
                        day.waitingTime = action.payload.departureWaitingMargin
                    }
                    return day
                }),
                priceBases: action.payload.priceBases,
                allowedToChangePriceBasis:
                    action.payload.allowedToChangePriceBasis,
            }
        }
        case SERIES_ACTIONS.SET_PRESCRIPTION_NUMBER: {
            return {
                ...state,
                prsNumber: action.payload.substring(0, 15).trim(),
            }
        }
        case SERIES_ACTIONS.SET_PRESCRIPTION_DATE: {
            return {
                ...state,
                prsDate: action.payload,
            }
        }
        case SERIES_ACTIONS.SET_KILOMETER: {
            return {
                ...state,
                kilometer: action.payload.replace(/[^0-9]+/g, ''),
                distanceNotFound: false,
            }
        }
        case SERIES_ACTIONS.SET_COMMENTS: {
            return {
                ...state,
                comments: action.payload,
            }
        }
        case SERIES_ACTIONS.SET_IS_SETUP_SERIES_VIEW: {
            return {
                ...state,
                isSetupSeriesView: action.payload,
            }
        }
        case SERIES_ACTIONS.SET_DISTANCE_NOT_FOUND: {
            return {
                ...state,
                distanceNotFound: true,
            }
        }
        case SERIES_ACTIONS.SET_BY_DATES_INTERVAL: {
            return {
                ...state,
                byIntervalDates: action.payload,
            }
        }
        case SERIES_ACTIONS.SET_OPEN_SELECT_START_DATE_PICKER: {
            return {
                ...state,
                openSelectStartDatePicker: action.payload,
            }
        }
        case SERIES_ACTIONS.SET_OPEN_SELECT_END_DATE_PICKER: {
            return {
                ...state,
                openSelectEndDatePicker: action.payload,
            }
        }
        case SERIES_ACTIONS.SET_START_DATE: {
            return {
                ...state,
                firstRunDate: action.payload.date,
                days: action.payload.days,
                daysOptions: action.payload.daysOptions,
                needsRefresh: true,
            }
        }
        case SERIES_ACTIONS.SET_END_DATE: {
            return {
                ...state,
                lastRunDate: action.payload,
                needsRefresh: true,
            }
        }
        case SERIES_ACTIONS.SET_NB_RUNS: {
            return {
                ...state,
                nbRuns: action.payload,
                needsRefresh: true,
            }
        }
        case SERIES_ACTIONS.SET_FREQUENCY: {
            return {
                ...state,
                frequency: action.payload,
                needsRefresh: true,
            }
        }
        case SERIES_ACTIONS.SET_DAYS_OPTIONS: {
            return {
                ...state,
                daysOptions: action.payload,
                needsRefresh: true,
            }
        }
        case SERIES_ACTIONS.SET_DAY: {
            return {
                ...state,
                needsRefresh: true,
                days: state.days.map((day) => {
                    if (day.day === action.payload.oldDay) {
                        day.day = action.payload.newDay
                    }
                    return day
                }),
                daysOptions: [
                    ...state.daysOptions.filter(
                        (dayOptions) =>
                            dayOptions.value !== action.payload.newDay
                    ),
                    state.daysDefaultList.find(
                        (d) => d.value === action.payload.oldDay
                    ),
                ],
            }
        }
        case SERIES_ACTIONS.ADD_DAY: {
            return {
                ...state,
                days: [...state.days, action.payload],
                daysOptions: state.daysOptions.filter(
                    (d) => d.value !== action.payload.day
                ),
                needsRefresh: true,
            }
        }
        case SERIES_ACTIONS.REMOVE_DAY: {
            return {
                ...state,
                days: state.days.filter((d) => d.day !== action.payload),
                daysOptions: [
                    ...state.daysOptions,
                    state.daysDefaultList.find(
                        (d) => d.value === action.payload
                    ),
                ],
                needsRefresh: true,
            }
        }
        case SERIES_ACTIONS.SET_DAY_DEPARTURE_TIME: {
            return {
                ...state,
                days: state.days.map((day) => {
                    if (day.day === action.payload.day) {
                        day.departureTime = action.payload.departureTime
                        day.arrivingTimeError =
                            day.arrivingTime <= day.departureTime
                        day.returnTimeError = day.returnTime <= day.arrivingTime
                    }
                    return day
                }),
                needsRefresh: true,
            }
        }
        case SERIES_ACTIONS.SET_DAY_ARRIVING_TIME: {
            return {
                ...state,
                days: state.days.map((day) => {
                    if (day.day === action.payload.day) {
                        day.arrivingTime = action.payload.arrivingTime
                        day.arrivingTimeError =
                            day.arrivingTime <= day.departureTime
                    }
                    return day
                }),
                needsRefresh: true,
            }
        }
        case SERIES_ACTIONS.SET_DAY_RETURN_TIME: {
            return {
                ...state,
                days: state.days.map((day) => {
                    if (day.day === action.payload.day) {
                        day.returnTime = action.payload.returnTime
                        day.returnTimeError = day.returnTime <= day.arrivingTime
                    }
                    return day
                }),
                needsRefresh: true,
            }
        }
        case SERIES_ACTIONS.SET_DAY_WAITING_TIME: {
            return {
                ...state,
                days: state.days.map((day) => {
                    if (day.day === action.payload.day) {
                        day.waitingTime = action.payload.waitingTime
                    }
                    return day
                }),
                needsRefresh: true,
            }
        }
        case SERIES_ACTIONS.SET_DAY_RUN_TYPE: {
            return {
                ...state,
                days: state.days.map((day) => {
                    if (day.day === action.payload.day) {
                        day.runType = action.payload.runType
                        day.isReturnPath = action.payload.runType.value === 2
                        day.isRoundTrip =
                            action.payload.runType.value === 3 ||
                            action.payload.runType.value === 4
                        day.isDirectReturn = action.payload.runType.value === 3
                    }
                    return day
                }),
                needsRefresh: true,
            }
        }
        case SERIES_ACTIONS.SET_DAY_IS_DIFFERENT_RETURN_DEPOSIT: {
            return {
                ...state,
                days: state.days.map((day) => {
                    if (day.day === action.payload.day) {
                        day.differentReturnDeposit =
                            action.payload.differentReturnDeposit
                    }
                    return day
                }),
            }
        }
        case SERIES_ACTIONS.SET_DAY_COMMENTS: {
            return {
                ...state,
                days: state.days.map((day) => {
                    if (day.day === action.payload.day) {
                        day.comments = action.payload.comments
                    }
                    return day
                }),
            }
        }
        case SERIES_ACTIONS.SET_RUNS_TO_INSERT: {
            return {
                ...state,
                runsToInsert: action.payload,
                needsRefresh: false,
            }
        }
        case SERIES_ACTIONS.SET_RUN_TO_INSERT_DATE: {
            return {
                ...state,
                runsToInsert: state.runsToInsert.map((run) => {
                    if (run.key === action.payload.key) {
                        // change date for departure and arriving time when date change

                        const date = action.payload.newDate
                        let newDepartureTime = new Date(run.departureTime)
                        let newArrivingTime = new Date(run.arrivingTime)

                        newDepartureTime.setDate(date.getDate())
                        newDepartureTime.setMonth(date.getMonth())
                        newDepartureTime.setFullYear(date.getFullYear())
                        newDepartureTime.setTime(newDepartureTime.getTime())
                        newArrivingTime.setDate(date.getDate())
                        newArrivingTime.setMonth(date.getMonth())
                        newArrivingTime.setFullYear(date.getFullYear())
                        newArrivingTime.setTime(newArrivingTime.getTime())

                        let newReturnTime = null
                        if (run.haveIndirectReturn) {
                            newReturnTime = new Date(run.returnTime)
                            newReturnTime.setDate(date.getDate())
                            newReturnTime.setMonth(date.getMonth())
                            newReturnTime.setFullYear(date.getFullYear())
                            newReturnTime.setTime(newReturnTime.getTime())
                        }

                        run.dateString = action.payload.dateString
                        run.date = date
                        run.departureTime = newDepartureTime
                        run.arrivingTime = newArrivingTime
                        run.returnTime = newReturnTime
                    }
                    return run
                }),
            }
        }
        case SERIES_ACTIONS.SET_RUN_TO_INSERT_DEPARTURE_TIME: {
            return {
                ...state,
                runsToInsert: state.runsToInsert.map((run) => {
                    if (run.key === action.payload.key) {
                        run.departureTime = action.payload.value
                        run.arrivingTimeError =
                            run.arrivingTime <= action.payload.value
                    }
                    return run
                }),
            }
        }
        case SERIES_ACTIONS.SET_RUN_TO_INSERT_ARRIVING_TIME: {
            return {
                ...state,
                runsToInsert: state.runsToInsert.map((run) => {
                    if (run.key === action.payload.key) {
                        run.arrivingTime = action.payload.value
                        run.arrivingTimeError =
                            action.payload.value <= run.departureTime
                        run.returnTimeError =
                            run.returnTime &&
                            run.returnTime <= action.payload.value
                    }
                    return run
                }),
            }
        }
        case SERIES_ACTIONS.SET_RUN_TO_INSERT_RETURN_TIME: {
            return {
                ...state,
                runsToInsert: state.runsToInsert.map((run) => {
                    if (run.key === action.payload.key) {
                        run.returnTime = action.payload.value
                        run.returnTimeError =
                            run.haveIndirectReturn &&
                            run.returnTime <= run.arrivingTime
                    }
                    return run
                }),
            }
        }
        case SERIES_ACTIONS.SET_RUN_TO_INSERT_WAITING_TIME: {
            return {
                ...state,
                runsToInsert: state.runsToInsert.map((run) => {
                    if (run.key === action.payload.key) {
                        run.waitingTime = action.payload.value
                            ? action.payload.value
                            : 1
                    }
                    return run
                }),
            }
        }
        case SERIES_ACTIONS.SET_RUN_TO_INSERT_TEAM: {
            return {
                ...state,
                runsToInsert: state.runsToInsert.map((run) => {
                    if (run.key === action.payload.key) {
                        run.team = action.payload.value

                        if (action.payload.value) {
                            const splitValue =
                                action.payload.value.value.split('/')

                            // si c'est une équipe par défaut je set l'id de l'équipe par défaut sinon celle de l'équipe
                            if (splitValue[1].includes('default')) {
                                run.defaultMasterRunId = action.payload.value
                                    ? action.payload.value.value.split('/')[2]
                                    : null
                                if (run.isRoundTrip) {
                                    run.returnRunDefaultMasterRunId = action
                                        .payload.value
                                        ? action.payload.value.value.split(
                                              '/'
                                          )[2]
                                        : null
                                }
                            } else {
                                run.masterRunId = action.payload.value
                                    ? action.payload.value.value.split('/')[2]
                                    : null
                                if (run.isRoundTrip) {
                                    run.returnRunMasterRunId = action.payload
                                        .value
                                        ? action.payload.value.value.split(
                                              '/'
                                          )[2]
                                        : null
                                }
                            }
                        } else {
                            run.defaultMasterRunId = null
                            run.masterRunId = null
                            run.returnRunDefaultMasterRunId = null
                            run.returnRunMasterRunId = null
                        }
                    }
                    return run
                }),
            }
        }
        case SERIES_ACTIONS.DELETE_RUN_TO_INSERT: {
            return {
                ...state,
                runsToInsert: state.runsToInsert.filter(
                    (run) => run.key !== action.payload.key
                ),
                nbRuns: !state.byIntervalDates
                    ? action.payload.isRoundTrip
                        ? parseInt(state.nbRuns) - 2
                        : parseInt(state.nbRuns) - 1
                    : null,
            }
        }
        case SERIES_ACTIONS.ADD_NEW_RUN_TO_INSERT: {
            return {
                ...state,
                runsToInsert: [...state.runsToInsert, action.payload],
                nbRuns: !state.byIntervalDates
                    ? action.payload.isRoundTrip
                        ? parseInt(state.nbRuns) + 2
                        : parseInt(state.nbRuns) + 1
                    : null,
                openAddMenu: false,
            }
        }
        case SERIES_ACTIONS.SET_SERIES: {
            return { ...state, ...action.payload }
        }
        case SERIES_ACTIONS.SET_OPEN_ADD_MENU: {
            return { ...state, openAddMenu: action.payload }
        }
        case SERIES_ACTIONS.SET_LOADING: {
            return {
                ...state,
                loading: action.payload,
                openValidationModal: false,
            }
        }
        case SERIES_ACTIONS.SET_OPEN_VALIDATION_MODAL: {
            return { ...state, openValidationModal: action.payload }
        }
        case SERIES_ACTIONS.UPDATE_INVOICE: {
            // update only the invoice with the same id and if id not present add the invoices to array
            const cpamInvoices = state.cpamInvoices.map((invoice) => {
                if (invoice.id === action.payload.id) {
                    return action.payload
                }
                return invoice
            })
            if (
                !cpamInvoices.find(
                    (invoice) => invoice.id === action.payload.id
                )
            ) {
                cpamInvoices.push(action.payload)
            }

            return { ...state, cpamInvoices: cpamInvoices }
        }
        case SERIES_ACTIONS.SET_TEAMS: {
            return { ...state, teams: action.payload }
        }
        default:
            return state
    }
}
