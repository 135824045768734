import dayjs from 'dayjs'
import * as isBetween from 'dayjs/plugin/isBetween'
import { toast } from 'react-toastify'
import API from '../../../../services/api'
import {
    dateStringToObject,
    getDateFromDateReducer,
    hasOneDateParts,
    isDateValid,
} from '../../../../services/DateService'
import {
    checkIsBetween,
    findCurrentPriceBasis,
} from '../../../../services/InvoiceUtils'
import { getInvoiceErrorMessage } from '../../../../services/fetchRunsInformations'
import {
    transformObjectsIntoIris,
    verifyKeyType1And2,
} from '../../../../tools/Utility'
import { formatDateToApi } from '../../../../services/dateUtils'
import { checkPECErrors } from '../../../../services/patient'
dayjs.extend(isBetween)

export const checkAddPrescriptionToSeries = (
    prescription,
    patient,
    firstRunDate,
    prsDate,
    dateMaternity,
    workAccidentDate,
    priorApprovalDate
) => {
    let msg = []
    const date = new Date(dayjs(firstRunDate).format('YYYY-MM-DD'))
    if (!isDateValid(prsDate, true)) {
        msg.push('Veuillez renseigner une date de prescription correcte')
    } else if (getDateFromDateReducer(prsDate) > date) {
        msg.push(
            'La date de prescription ne peut pas être supérieur à la date du premier transport'
        )
    }
    if (prescription.natureAssurance?.value === '41') {
        if (hasOneDateParts(workAccidentDate)) {
            if (!isDateValid(workAccidentDate, true)) {
                msg.push(
                    "Veuillez renseigner une date d'accident du travail correcte"
                )
            } else if (getDateFromDateReducer(workAccidentDate) > date) {
                msg.push(
                    "La date d'accident du travail ne peut pas être supérieur à la date du premier transport"
                )
            }
        }
        if (prescription.workAccidentNumber) {
            if (!verifyKeyType1And2(prescription.workAccidentNumber)) {
                msg.push("Le numéro d'accident est incorrect")
            }
        }

        if (
            !isDateValid(workAccidentDate) &&
            !prescription.workAccidentNumber
        ) {
            msg.push(
                'Veuillez renseigner une date d accident du travail ou un numéro d accident du travail'
            )
        }
    }
    if (prescription.natureAssurance?.value === '30') {
        if (hasOneDateParts(dateMaternity)) {
            if (!isDateValid(dateMaternity, true)) {
                msg.push('Veuillez renseigner une date de grossesse correcte')
            } else if (getDateFromDateReducer(dateMaternity) > date) {
                msg.push(
                    'La date de grossesse ne peut pas être supérieur à la date du premier transport'
                )
            }
        } else {
            msg.push('Veuillez renseigner une date de grossesse correcte')
        }
    }
    if (prescription.isPriorApproval) {
        if (hasOneDateParts(priorApprovalDate)) {
            if (!isDateValid(priorApprovalDate, true)) {
                msg.push(
                    "Veuillez renseigner une date d'accord préalable correcte"
                )
            } else if (getDateFromDateReducer(priorApprovalDate) > date) {
                msg.push(
                    "La date d'accord préalable ne peut pas être supérieur à la date du premier transport"
                )
            }
        } else {
            msg.push("Veuillez renseigner une date d'accord préalable correcte")
        }
    }
    if (!prescription.natureAssurance) {
        msg.push("Veuillez renseigner la nature de l'assurance.")
    }
    if (!prescription.practitioner?.id) {
        msg.push('Veuillez renseigner le praticien.')
    }
    if (!prescription.rate?.value) {
        msg.push('Veuillez renseigner le taux de remboursement.')
    }
    if (!prescription.exoTicketModerateur) {
        msg.push('Veuillez renseigner le ticket modérateur.')
    }
    if (!patient?.healthComplementary && prescription.rate?.value !== '100') {
        msg.push('Veuillez renseigner une complémentaire santé.')
    }

    if (msg.length > 0) {
        msg.forEach((e) => {
            toast.error(e)
        })
        return true
    }

    return false
}
export const checkIfSerieIsAbleToBeInvoiced = (
    prescription,
    patient,
    firstRunDate,
    prsDate,
    dateMaternity,
    workAccidentDate,
    priorApprovalDate
) => {
    if (!prescription['@id']) {
        toast.error(
            'La prescription doit être ajoutée à la série avant de facturer.'
        )
        return true
    } else {
        return checkAddPrescriptionToSeries(
            prescription,
            patient,
            firstRunDate,
            prsDate,
            dateMaternity,
            workAccidentDate,
            priorApprovalDate
        )
    }
}

export function checkVehicleConventionDates(run, msg, team) {
    if (team?.vehicle.conventionDateFrom) {
        const startDate = dayjs.utc(team.vehicle.conventionDateFrom)
        const endDate = dayjs.utc(team.vehicle.conventionDateTo)
        const runDate = new Date(run.date)
        if (!checkIsBetween(runDate, startDate, endDate)) {
            msg.push(
                'Impossible de facturer un transport sur un véhicule non conventionné : ' +
                    dayjs(team.vehicle.conventionDateFrom).format(
                        'DD/MM/YYYY'
                    ) +
                    ' - ' +
                    dayjs(team.vehicle.conventionDateTo).format('DD/MM/YYYY')
            )
        }
    }
}

export const getErrorMessage = (patient, invoiceForm, prescription, run) => {
    let msg = []
    const validStatuses = ['Fait', 'Facturé']
    patient = patient.parent ? patient.parent : patient

    if (!validStatuses.includes(run.status)) {
        msg.push(
            'Le transport doit être au statut fait ou facturé pour être facturé/modifié, statut actuel : ' +
                run.status
        )
    }
    if (!run.team) {
        msg.push('Le transport doit être affecté à une équipe.')
    }
    if (!prescription.natureAssurance) {
        msg.push("Veuillez renseigner la nature de l'assurance.")
    }
    if (!prescription.practitioner) {
        msg.push('Veuillez renseigner le praticien.')
    }
    if ((run.kilometer && run.kilometer === 0) || !run.kilometer) {
        msg.push('Le kilométrage doit être renseigné.')
    }

    if (!prescription.exoTicketModerateur) {
        msg.push('Veuillez renseigner le ticket modérateur.')
    }
    if (!prescription.rate?.value) {
        msg.push('Veuillez renseigner le taux de remboursement.')
    }
    if (!patient.payingCenter) {
        msg.push('Veuillez renseigner le centre payeur.')
    }
    if (
        !patient.healthComplementary &&
        prescription.rate?.value !== '100' &&
        !run.allowedToInvoiceWithoutAMC
    ) {
        msg.push(
            'Veuillez renseigner une complémentaire santé ou activer la "Facturation sans AMC" dans  Paramètres > Télétransmission'
        )
    }
    if (run.runType?.value === 3 || run.runType?.value === 4) {
        const runRetour = run.returnId ? run.returnId : null
        if (
            !validStatuses.includes(run.linkedRunStatus) &&
            !runRetour &&
            !run.linkedRun?.isGiven
        ) {
            msg.push(
                'Le transport « Aller » doit être au statut « Fait » pour être facturé, statut actuel : ' +
                    run.linkedRunStatus
            )
        } else if (
            !validStatuses.includes(run.linkedRunStatus) &&
            !run.linkedRun.isGiven
        ) {
            msg.push(
                'Le transport « Retour » doit être au statut Fait » pour être facturé, statut actuel : ' +
                    run.linkedRunStatus
            )
        }
        if (!run.linkedRun?.isGiven && !run.linkedRun?.masterRun) {
            msg.push(
                'Les deux transports doivent avoir une équipe assignée pour être facturés'
            )
        }
    }
    const priceBasis = findCurrentPriceBasis(run.priceBases, run.date)

    if (!priceBasis) {
        msg.push(
            "Aucune base tarifaire n'est disponible dans cette tranche de date"
        )
    }
    // add the check for the invoice form to the msg array
    const invoiceErrorMsg = getInvoiceErrorMessage(run, patient)
    // merge both array
    msg = [...msg, ...invoiceErrorMsg]

    return msg
}

export const checkInvoiceForm = (
    patient,
    invoiceForm,
    prescription,
    run,
    pickUpAddress,
    depositAddress
) => {
    run.pickUpLocation = pickUpAddress
    run.depositLocation = depositAddress
    const msg = getErrorMessage(patient, invoiceForm, prescription, run)
    if (msg.length > 0) {
        msg.forEach((e) => {
            toast.error(e, { autoClose: false })
        })
        return true
    }

    return false
}

export const handleInvoiceForm = async (
    invoiceForm,
    run,
    prescription,
    patient
) => {
    let result = { success: false, data: null }
    console.log('invoiceForm', invoiceForm)
    const dataInvoiceForm = transformInvoiceObject(
        invoiceForm,
        run,
        prescription,
        patient
    )

    let msg = ''
    const patientToCheck = patient.parent ? patient.parent : patient
    if (
        !checkPECErrors(patientToCheck, dateStringToObject(patient.dateOfBirth))
    ) {
        try {
            let response
            if (dataInvoiceForm.id) {
                response = await API.CpamInvoice.put(
                    dataInvoiceForm.id,
                    dataInvoiceForm
                )
                msg = 'Facture modifiée avec succès'
            } else {
                response = await API.CpamInvoice.post(dataInvoiceForm)
                msg = 'Facture ajoutée avec succès'
            }

            toast.success(msg)
            result.success = true
            result.data = response
        } catch (error) {
            if (error.json) {
                const data = await error.json()
                toast.error(data['hydra:description'])
            } else {
                toast.error('Erreur, veuillez contacter un administrateur')
            }
            result.success = false
            result.data = null
        }
    }
    return result
}

const transformInvoiceObject = (
    invoiceObject,
    run = null,
    prescription,
    patient
) => {
    const priceBasis = !invoiceObject.priceBasis
        ? findCurrentPriceBasis(run.priceBases, run.date)
        : invoiceObject.priceBasis
    const basePath = '/runs/'

    // get the runId inside the runsInvoiceFormsDetails and spread
    const runs = invoiceObject.runsInvoiceFormsDetails.map((detail) => {
        const runInvoiceDetails = {
            ...detail,
            priceBasis: detail.priceBasis['@id'],
        }

        const totalAmount = parseFloat(detail.totalAmount.toFixed(2))
        const totalBeforeAbatement = parseFloat(
            detail.totalBeforeAbatement?.toFixed(2)
        )
        const abatementAmount = totalAmount - totalBeforeAbatement
        return {
            ['@id']: basePath + detail.runId,
            runInvoiceDetails: {
                ...runInvoiceDetails,
                abatement: detail.abatement ? detail.abatement.value : null,
                abatementAmount: Math.abs(
                    detail.totalBeforeAbatement > 0 ? abatementAmount : null
                ),
                nbPeople: detail.nbPeople ? parseInt(detail.nbPeople) : 1,
                totalDiscount: parseFloat(
                    parseFloat(Math.abs(detail.totalDiscount)).toFixed(2)
                ),
                kilometerA: parseFloat(detail.kilometerA),
                totalAmount: totalAmount,
                kilometerB: parseFloat(detail.kilometerB),
                kilometerC: parseFloat(detail.kilometerC),
                kilometerD: parseFloat(detail.kilometerD),
            },
        }
    })

    const kilometerTypes = ['A', 'B', 'C', 'D']
    const kilometers = {}

    kilometerTypes.forEach((type) => {
        kilometers[`kilometer${type}`] = runs.reduce((acc, run) => {
            const detail = run.runInvoiceDetails.details.find(
                (detail) => detail.tarifType === type
            )
            return acc + (detail ? detail.kilometer : 0)
        }, 0)
    })

    const patientInvoiceInfo = patient.parent ? patient.parent : patient

    return {
        id: invoiceObject.id,
        patient: patient['@id'],
        runObject: run.runObject['@id'],
        prescription: prescription['@id'],
        payingCenter: transformObjectsIntoIris(patientInvoiceInfo.payingCenter),
        healthComplementary:
            patientInvoiceInfo.healthComplementary &&
            prescription.rate.value !== '100'
                ? transformObjectsIntoIris(
                      patientInvoiceInfo.healthComplementary
                  )
                : null,
        totalInvoiceAmount: parseFloat(
            invoiceObject.totals.totalAmount.toFixed(2)
        ),
        payingCenterAmount: parseFloat(
            invoiceObject.totals.totalAmo.toFixed(2)
        ),
        totalRefundBase: parseFloat(
            invoiceObject.totals.totalAmount.toFixed(2)
        ),
        healthComplementaryAmount: parseFloat(
            invoiceObject.totals.totalAmc.toFixed(2)
        ),
        priceBasis: '/price_bases/' + priceBasis.id,
        kilometerA: parseFloat(kilometers.kilometerA),
        kilometerB: parseFloat(kilometers.kilometerB),
        kilometerC: parseFloat(kilometers.kilometerC),
        kilometerD: parseFloat(kilometers.kilometerD),
        tarifPC: invoiceObject.totals.totalPecAmount,
        runs: runs,
        date: formatDateToApi(invoiceObject.date),
    }
}

const calculateInitialPrice = (totalAfterReduction, reductionPercentage) => {
    const initialPrice = totalAfterReduction / (1 - reductionPercentage / 100)
    return initialPrice.toFixed(2) // Pour garder 2 chiffres après la virgule
}
