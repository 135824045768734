import * as dayjs from 'dayjs'
import { InvoiceStatus } from '../InvoiceStatus'

export const INVOICE_ACTIONS = {
    SET_DATE: 'SET_DATE',
    SET_PATIENT: 'SET_PATIENT',
    SET_INVOICER: 'SET_INVOICER',
    SET_PAYMENT_METHOD: 'SET_PAYMENT_METHOD',
    SET_ANNEXES: 'SET_ANNEXES',
    SET_COMMENTS: 'SET_COMMENTS',
    SET_LABEL: 'SET_LABEL',
    SET_VAT: 'SET_VAT',
    SET_QUANTITY: 'SET_QUANTITY',
    SET_DISCOUNT: 'SET_DISCOUNT',
    SET_UNIT_PRICE: 'SET_UNIT_PRICE',
    SET_LINE_TTC: 'SET_LINE_TTC',
    SET_TTC: 'SET_TTC',
    ADD_NEW_LINE: 'SET_NEW_LINE',
    DELETE_LINE: 'DELETE_LINE',
    IS_A_PERSON_TO_BILL: 'IS_A_PERSON_TO_BILL',
    UPDATE_LINE: 'UPDATE_LINE',
    SET_ESTABLISHMENTS: 'SET_ESTABLISHMENTS',
    SHOW_ANNEXES: 'SHOW_ANNEXES',
    FETCH_INVOICE: 'FETCH_INVOICE',
}

export const InvoiceReducer = (state, action) => {
    switch (action.type) {
        case INVOICE_ACTIONS.SET_ESTABLISHMENTS:
            return {
                ...state,
                establishments: action.payload,
            }
        case INVOICE_ACTIONS.ADD_NEW_LINE:
            return {
                ...state,
                lines: [...state.lines, newInvoiceLine(state.lines.length + 1)],
            }
        case INVOICE_ACTIONS.DELETE_LINE:
            return {
                ...state,
                lines: state.lines.filter(
                    (line) => line.id !== action.payload.id
                ),
            }

        case INVOICE_ACTIONS.UPDATE_LINE:
            return {
                ...state,
                lines: [
                    ...state.lines.map((line) => {
                        if (line.id === action.payload.id) {
                            return {
                                ...action.payload,
                            }
                        } else {
                            return line
                        }
                    }),
                ],
            }
        case INVOICE_ACTIONS.SET_DATE:
            return { ...state, date: action.payload }
        case INVOICE_ACTIONS.IS_A_PERSON_TO_BILL:
            return {
                ...state,
                isAPersonToBill: action.payload,
                invoicer: action.payload ? null : action.payload,
            }
        case INVOICE_ACTIONS.SET_PAYMENT_METHOD:
            return { ...state, paymentMethod: action.payload }
        case INVOICE_ACTIONS.SET_INVOICER:
            return { ...state, invoicer: action.payload.establishment }
        case INVOICE_ACTIONS.SET_COMMENTS:
            return {
                ...state,
                comments:
                    action.payload.split(/\n/).length <= 3 &&
                    action.payload.length <= 300
                        ? action.payload
                        : state.comments,
            }
        case INVOICE_ACTIONS.SHOW_ANNEXES:
            return { ...state, showAnnexes: action.payload }
        case INVOICE_ACTIONS.SET_PATIENT:
            return { ...state, patient: action.payload }
        case INVOICE_ACTIONS.SET_ANNEXES:
            const amounts = action.payload.filter((annexe) => annexe.amount > 0)
            return {
                ...state,
                annexes: action.payload,
                lines:
                    amounts.length > 0
                        ? [
                              ...state.lines,

                              ...amounts.map((amt) => {
                                  return newInvoiceLine(
                                      state.lines.length + 1,
                                      amt
                                  )
                              }),
                          ]
                        : state.lines,
            }
        case INVOICE_ACTIONS.FETCH_INVOICE:
            return {
                ...state,
                invoice: action.payload,
                patient: action.payload.patient,
                invoicer: action.payload.invoicer,
                isAPersonToBill: !action.payload.invoicer,
                paymentMethod: action.payload.paymentMethod,
                date: new Date(action.payload.date),
                comments: action.payload.commentaire,
                loading: false,
                annexes: action.payload.runs,
                lines: action.payload.invoiceLines.map((line) => {
                    return {
                        ...line,
                        unitPrice: line.unitPrice,
                        lineTTC: line.lineTTC,
                        ttc: line.ttc,
                        discount: line.discount,
                        quantity: line.quantity,
                        vat: line.vat.toString(),
                    }
                }),
            }
        default:
            return state
    }
}

export const getInitialState = ({ patient, invoiceRuns, dateTo }) => {
    const amounts = invoiceRuns.filter((annexe) => annexe.amount > 0)
    return {
        date: dateTo,
        loading: false,
        patient: patient,
        isAPersonToBill: true,
        paymentMethod: null,
        invoicer: null,
        comments: '',
        number: null,
        establishments: [],
        annexes: invoiceRuns,
        dateFrom: invoiceRuns.dateFrom ? invoiceRuns.dateFrom : new Date(),
        dateTo: invoiceRuns.dateTo ? invoiceRuns.dateTo : new Date(),
        showAnnexes: false,
        invoice: null,
        status: InvoiceStatus[0]['@id'],
        lines:
            amounts.length > 0
                ? amounts.map((amt, index) => {
                      return newInvoiceLine(index + 1, amt)
                  })
                : [newInvoiceLine(1)],
    }
}

const newInvoiceLine = (i, run) => {
    return {
        id: 'Invoice_Line_' + i,
        label: run ? `Course du ${dayjs(run.date).format('DD/MM/YY')}` : '',
        quantity: 1,
        unitPrice: run?.amount ? run.amount : 0.0,
        discount: 0.0,
        vat: '10',
        totalPrice: 0.0,
    }
}
