import React, { useEffect, useState } from 'react'
import * as dayjs from 'dayjs'
import API from '../../../../services/api'
import Loader from '../../../Commons/Loaders/Loader'
import NoData from '../../NoData'
import FilterContainer from '../FilterContainer'
import LotListComponent from './LotListComponent'

const LotList = ({
    dateFrom,
    transferGroup,
    setAddToTransferGroup,
    dateTo,
    search,
    archived,
    openFilterModal,
    firstLoading,
    setFirstLoading,
    setOpenFilterModal,
    isReloading,
}) => {
    const [totalItems, setTotalItems] = useState(0)
    const [loading, setLoading] = useState(true)
    const [lots, setLots] = useState([])

    const getLots = () => {
        const between = {
            strictlyBefore: dayjs(dateTo).format('YYYY-MM-DD'),
            strictlyAfter: dayjs(dateFrom).format('YYYY-MM-DD'),
        }
        setLoading(true)
        API.CpamInvoiceGroup.list(search, between, false).then((response) => {
            response.json().then((data) => {
                setLots(data['hydra:member'])
                setTotalItems(data['hydra:totalItems'])
                setLoading(false)
                setFirstLoading(false)
            })
        })
    }

    useEffect(() => {
        getLots()
    }, [search, dateFrom, dateTo, archived, isReloading])

    return (
        <div>
            {firstLoading ? (
                <Loader />
            ) : lots.length > 0 ? (
                <LotListComponent
                    transferGroup={transferGroup}
                    setAddToTransferGroup={setAddToTransferGroup}
                    lots={lots}
                    totalItems={totalItems}
                    loading={loading}
                    openFilterModal={openFilterModal}
                    setOpenFilterModal={setOpenFilterModal}
                />
            ) : (
                <NoData message="Aucun lot disponible" />
            )}
            {openFilterModal && (
                <FilterContainer
                    transferGroup={transferGroup}
                    setOpenFilterModal={setOpenFilterModal}
                    openFilterModal={openFilterModal}
                />
            )}
        </div>
    )
}

export default LotList
