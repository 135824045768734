import * as dayjs from 'dayjs'
import { formatEuro } from '../Manage'
import React from 'react'
import { RenderInvoiceId } from '../../../../services/InvoiceUtils'

const InvoiceCpamItem = ({
    invoice,
    selectedInvoices,
    handleInvoiceSelection,
    navigateToinvoice,
}) => {
    const isSelected = selectedInvoices.includes(invoice.id)
    const isSuspended = invoice.status?.label === 'Suspendue'

    return (
        <div
            className={`flex w-full cursor-pointer pb-2`}
            key={invoice.id}
            onClick={
                isSuspended
                    ? () => navigateToinvoice(invoice)
                    : () => handleInvoiceSelection(invoice.id, invoice)
            }
        >
            <div
                className={`grid w-full ${
                    isSelected ? 'bg-primary-color text-white' : 'bg-white'
                } ${
                    isSuspended ? 'stripeBg' : ''
                } select-none rounded shadow-mobile hover:scale-[1.02] lg:select-auto`}
            >
                <div className="parents row-span-1 flex grid-rows-1 flex-wrap items-center justify-between px-2 py-2 text-sm lg:text-base">
                    <div className="w-1/2 truncate sm:w-1/4">
                        <div>
                            {dayjs(invoice.createdAt).format('DD/MM/YYYY')}
                        </div>
                        <div className="font-bold">
                            {invoice.patient.lastname}{' '}
                            {invoice.patient.firstname}
                        </div>
                    </div>
                    <div className="w-1/2 truncate sm:w-1/4">
                        <span>N°de facture</span>
                        <RenderInvoiceId invoiceId={invoice.cpamInvoiceId} />
                    </div>
                    <div className="w-1/2 truncate text-center sm:w-1/4">
                        <div>{invoice.payingCenter.label}</div>
                        <span className="font-semibold">
                            {invoice.payingCenterAmount}€
                        </span>
                    </div>
                    <div className="w-1/2 truncate text-center sm:w-1/4">
                        {invoice.prescription.rate !== '100' ? (
                            <>
                                <div>
                                    {invoice.healthComplementary
                                        ? invoice.healthComplementary.label
                                        : 'Assuré'}
                                </div>
                                <div className="font-bold">
                                    {formatEuro(
                                        invoice.healthComplementaryAmount
                                    )}
                                </div>
                            </>
                        ) : (
                            <div>
                                <span
                                    className={`flex inline-flex w-24 max-w-full justify-center rounded-full bg-blue-100 px-2 text-base  font-semibold leading-5 text-blue-500  `}
                                >
                                    100%
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoiceCpamItem
