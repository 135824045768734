import { debounce } from './debounce'
import API from './api'
import { DATE_ACTIONS } from './Reducers/DateReducer'
import { ADDRESS_ACTIONS } from './Reducers/AddressAutoCompleteReducer'
import { TRANSPORT_ACTIONS } from '../components/App/Regulation/Reducers/TransportFormReducer'
import { PRESCRIPTION_FORM_ACTIONS } from '../components/App/Regulation/Reducers/PrescriptionFormReducer'
import { toast } from 'react-toastify'
import { PATIENT_FORM_ACTIONS } from '../components/App/Regulation/Reducers/PatientFormReducer'
import { hasOneDateParts, isDateValid } from './DateService'

export const handlePatientChange = (
    e,
    dispatch,
    dateDispatch,
    pickUpDispatch,
    depositDispatch,
    run,
    prescriptionForm
) => {
    const defaultPickUpAddress = e?.patient.defaultPickUpAddress
        ? e.patient.defaultPickUpAddress
        : run.pickUpLocation
    const defaultDepositAddress = e?.patient.defaultDepositAddress
        ? e.patient.defaultDepositAddress
        : run.depositLocation
    const isAld = e?.patient.isAld ? e?.patient.isAld : false
    if (!prescriptionForm.rate && !prescriptionForm.natureAssurance) {
        dispatch({
            type: PRESCRIPTION_FORM_ACTIONS.PREFILL_PRESCRIPTION_FORM,
            payload: isAld,
        })
    }
    dispatch({
        type: TRANSPORT_ACTIONS.SET_PATIENT,
        payload: e,
    })
    dateDispatch({
        type: DATE_ACTIONS.SET_DATE,
        payload: e?.patient.dateOfBirth
            ? e?.patient.dateOfBirth
            : e?.patient.stringDateOfBirth,
    })
    pickUpDispatch({
        type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
        payload: defaultPickUpAddress,
    })
    depositDispatch({
        type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
        payload: defaultDepositAddress,
    })

    if (e) {
        dispatch({
            type: PATIENT_FORM_ACTIONS.GET_PATIENT,
            payload: e.patient,
        })
    } else {
        dispatch({
            type: PATIENT_FORM_ACTIONS.CLEAR_PATIENT,
        })
    }
}

export const handleBeneficiaryForm = async (patient) => {
    let result = { success: false, data: null }
    let msg = ''
    // check if every beneficiary have a beneficiary link
    const checkIfThereIsLink = patient.beneficiariesForm.every(
        (beneficiary) => beneficiary.beneficiaryLink
    )

    const data = {
        beneficiaries: patient.beneficiariesForm || null,
    }
    if (checkIfThereIsLink) {
        try {
            let response
            if (patient.id) {
                response = await API.Patients.addBeneficiaries(patient.id, data)
                msg = 'Modifié avec succès.'
            }
            if (response.ok) {
                const jsonData = await response.json()
                toast.success(msg)
                result.success = true
                result.data = jsonData
            }
        } catch (error) {
            if (error.json) {
                const data = await error.json()
                toast.error(data['hydra:description'])
            } else {
                toast.error('Erreur, veuillez contacter un administrateur')
            }
            result.success = false
            result.data = null
        }
    } else {
        toast.error(
            'Veuillez renseigner le lien de parenté de chaque bénéficiaire.'
        )
    }
    return result
}

export const searchPatient = debounce(
    (e, dispatch, ACTION = TRANSPORT_ACTIONS.SET_PATIENT_SEARCH_INPUT) => {
        if (e !== '') {
            API.Patients.list(e).then((response) => {
                response.json().then((data) => {
                    const patientList = data['hydra:member'].map((patient) => {
                        return {
                            value: patient['@id'],
                            label: patient.firstname + ' ' + patient.lastname,
                            patient: patient,
                        }
                    })
                    dispatch({
                        type: ACTION,
                        payload: patientList,
                    })
                })
            })
        }
    },
    500
)

export const checkPECErrors = (patient, date, notif = true) => {
    let msg = []
    if (hasOneDateParts(date)) {
        if (!isDateValid(date, !patient.isLunarDate, patient.isLunarDate)) {
            msg.push('Veuillez renseigner une date de naissance correcte')
        }
    } else {
        msg.push('Veuillez renseigner une date de naissance ')
    }
    if (!patient.socialNumber || patient.socialNumber.length !== 15) {
        msg.push('Veuillez renseigner un numéro de sécurité sociale valide.')
    }
    if (!patient.birthRank) {
        msg.push('Veuillez renseigner un rang de naissance.')
    }
    if (!patient.payingCenter) {
        msg.push('Veuillez renseigner une caisse.')
    }
    if (msg.length > 0) {
        msg.forEach((e) => {
            toast.error(e, { autoClose: false })
        })
        return true
    }
    return false
}
