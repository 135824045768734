import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import '@fortawesome/fontawesome-free/css/all.css'
import '@geoapify/geocoder-autocomplete/styles/minimal.css'
import './index.css'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import App from './App'
import Gleap from 'gleap'
import { UserProvider } from './context/UserContext'
import { ParametersProvider } from './context/ParametersContext'
import { API_ENTRYPOINT } from './config/entrypoint'
import { BuildVersion } from './BuildVersion'
import { DeviceManager } from './DeviceManager'

const container = document.getElementById('root')
const root = createRoot(container)

async function subscribeToPushNotifications() {
    const registration = await navigator.serviceWorker.ready
    const deviceId = localStorage.getItem('deviceId')
    const userId = localStorage.getItem('id')

    if (deviceId) {
        try {
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey:
                    'BIeM5T03Nnw07ahs09QjzH07sPcwZmYZdzC4C6dGTNvhPt6Bzh4SAENSvgKcf6zV48aqHjMibA5K2Axg7xRSa9A',
            })
            const key = subscription.getKey('p256dh')
            const token = subscription.getKey('auth')
            const contentEncoding = (PushManager.supportedContentEncodings || [
                'aesgcm',
            ])[0]
            if (userId) {
                await fetch(`${API_ENTRYPOINT}/subscribe`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        ...DeviceManager.getDeviceInfo(),
                        endpoint: subscription.endpoint,
                        deviceId: deviceId,
                        appVersion: BuildVersion,
                        publicKey: key
                            ? btoa(
                                  String.fromCharCode.apply(
                                      null,
                                      new Uint8Array(key)
                                  )
                              )
                            : null,
                        authToken: token
                            ? btoa(
                                  String.fromCharCode.apply(
                                      null,
                                      new Uint8Array(token)
                                  )
                              )
                            : null,
                        userId: userId,
                        contentEncoding,
                    }),
                })
            } else {
                // Send the subscription and deviceId to your server
                await fetch(`${API_ENTRYPOINT}/subscribe`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        endpoint: subscription.endpoint,
                        deviceId: deviceId,
                        appVersion: BuildVersion,
                        publicKey: key
                            ? btoa(
                                  String.fromCharCode.apply(
                                      null,
                                      new Uint8Array(key)
                                  )
                              )
                            : null,
                        authToken: token
                            ? btoa(
                                  String.fromCharCode.apply(
                                      null,
                                      new Uint8Array(token)
                                  )
                              )
                            : null,
                        contentEncoding,
                    }),
                })
            }

            console.log('User re-subscribed to push notifications')
        } catch (error) {
            console.error('Failed to re-subscribe:', error)
        }
    }
}

root.render(
    <BrowserRouter>
        <UserProvider>
            <ParametersProvider>
                <App />
            </ParametersProvider>
        </UserProvider>
    </BrowserRouter>
)

// no register on localhost
if (process.env.NODE_ENV === 'production') {
    Gleap.setLanguage('fr')
    Gleap.initialize('adwXii6Uq3JGdV7A1rqFvXzW3053jYD5')
    console.log('registering service worker')
    serviceWorkerRegistration.register({
        onUpdate: async (registration) => {
            console.log('service worker update')
            // We want to run this code only if we detect a new service worker is
            // waiting to be activated.
            // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
            if (registration && registration.waiting) {
                // check the device id
                await registration.unregister()
                // Makes Workbox call skipWaiting()
                registration.waiting.postMessage({ type: 'SKIP_WAITING' })
                // Store a flag in localStorage to indicate we need to re-subscribe
                // localStorage.setItem('needsResubscribe', 'true')
                console.log('service worker updated')
                // Once the service worker is unregistered, we can reload the page to let
                // the browser download a fresh copy of our app (invalidating the cache)
                window.location.reload()
            }
        },
        onSuccess: async (registration) => {
            console.log('service worker registered')
            // if (localStorage.getItem('needsResubscribe') === 'true') {
            await subscribeToPushNotifications()
            // localStorage.removeItem('needsResubscribe')
            // }
        },
    })
    reportWebVitals()
}
