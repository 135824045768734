import React from 'react'
import { HelpCircle } from 'iconoir-react'

const StyledInput = ({
    label,
    id,
    type = 'text',
    required = false,
    helpText,
    ...props
}) => {
    return (
        <div>
            <label
                htmlFor={id}
                className="block text-sm font-medium text-gray-700"
            >
                {label} {required && <span className="text-red-500">*</span>}
                {helpText && (
                    <HelpCircle
                        className="ml-1 inline-block text-gray-400"
                        title={helpText}
                    />
                )}
            </label>
            <input
                type={type}
                id={id}
                name={id}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-secondary-color focus:outline-none focus:ring-secondary-color"
                required={required}
                {...props}
            />
        </div>
    )
}

export default StyledInput
