import React, { useState, useRef, useCallback } from 'react'
import { ADDRESS_ACTIONS } from '../../services/Reducers/AddressAutoCompleteReducer'
import TOOLS from '../../services/tools'
import LoaderModal from '../Commons/Loaders/LoaderModal'
import { formatAddressInfo } from '../../services/address'
import { Cancel, HomeUser, NavArrowDown } from 'iconoir-react'
import { debounce } from '../../services/debounce'

const GeoapifyAutocomplete = ({
    savedAddresses = [],
    address,
    dispatch,
    label,
    icon,
}) => {
    const [currentItems, setCurrentItems] = useState(savedAddresses)
    const [focusedItemIndex, setFocusedItemIndex] = useState(-1)
    const [show, setShow] = useState(false)
    const [visible, setVisible] = useState('hidden')
    const [isLoading, setIsLoading] = useState(false)

    const inputRef = useRef(null)
    const autocompleteItemsRef = useRef(null)

    let componentRestrictions = {
        country: ['fr', 'ch', 'be', 'es', 'mq'],
    }

    const restrictToCountry = JSON.parse(
        localStorage.getItem('countryCodeRestrictions')
    )
    if (restrictToCountry) {
        componentRestrictions = restrictToCountry
    }

    TOOLS.useOnClickOutside(autocompleteItemsRef, () => {
        dispatch({
            type: ADDRESS_ACTIONS.SHOW_PREDICTIONS,
            payload: false,
        })
    })

    const debouncedFetch = useCallback(
        debounce(async (value) => {
            if (!value) {
                setCurrentItems(savedAddresses)
                return
            }

            setIsLoading(true)
            try {
                const apiKey = '45ee1b1f4ec649caab1011e60d157f2c'
                const url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${encodeURIComponent(
                    value
                )}&limit=5&apiKey=${apiKey}&lang=fr&filter=countrycode:${componentRestrictions.country.join(
                    ','
                )}`

                const response = await fetch(url)
                if (response.ok) {
                    const data = await response.json()
                    const combinedItems = [...data.features, ...savedAddresses]
                    setCurrentItems(combinedItems)
                    dispatch({
                        type: ADDRESS_ACTIONS.SHOW_PREDICTIONS,
                        payload: true,
                    })
                } else {
                    console.error('Geoapify API error:', await response.json())
                }
            } catch (error) {
                console.error('Fetch error:', error)
            } finally {
                setIsLoading(false)
            }
        }, 300),
        [dispatch]
    )

    const handleAddressChange = (evt) => {
        const currentValue = evt.target.value
        dispatch({
            type: ADDRESS_ACTIONS.UPDATE_ADDRESS_FIELD,
            payload: currentValue,
        })

        if (!currentValue) {
            dispatch({
                type: ADDRESS_ACTIONS.DELETE_ADDRESS,
                payload: currentValue,
            })
            setCurrentItems(savedAddresses)
            return
        }

        debouncedFetch(currentValue)
    }

    const handleClickOnPrediction = async (prediction) => {
        const addressInfo = await formatAddressInfo(prediction)
        dispatch({
            type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
            payload: addressInfo,
        })
        const addedAddressInfo = await formatAddressInfo(prediction, false)
        dispatch({
            type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
            payload: addedAddressInfo,
        })
        dispatch({
            type: ADDRESS_ACTIONS.SHOW_PREDICTIONS,
            payload: false,
        })
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 40) {
            // Arrow down
            e.preventDefault()
            setFocusedItemIndex((prev) =>
                prev < currentItems.length - 1 ? prev + 1 : 0
            )
        } else if (e.keyCode === 38) {
            // Arrow up
            e.preventDefault()
            setFocusedItemIndex((prev) =>
                prev > 0 ? prev - 1 : currentItems.length - 1
            )
        } else if (e.keyCode === 13) {
            // Enter
            e.preventDefault()
            if (focusedItemIndex > -1) {
                handleClickOnPrediction(currentItems[focusedItemIndex])
            }
        }
    }

    const showAddressFields = () => {
        if (!show) {
            setVisible('')
            setTimeout(() => {
                setShow(!show)
            }, 1)
        } else {
            setShow(!show)
            setTimeout(() => {
                setVisible('hidden')
            }, 300)
        }
    }

    return (
        <div className="flex w-full flex-col">
            <div className="relative flex">
                <div className="relative w-full pr-2">
                    <div className="flex space-x-2">
                        {icon && (
                            <i className="fas fa-map-marker-alt fa-lg min-w-icon fa-lg" />
                        )}
                        <label className="mb-2 block text-base font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                            {label}
                        </label>
                    </div>
                    <div
                        className={`flex ${
                            show
                                ? 'bg-gray-200'
                                : 'bg-white focus-within:border-lighter-blue-green hover:border-lighter-blue-green'
                        } block w-full items-center rounded-md border px-3 leading-tight text-gray-700`}
                    >
                        <input
                            ref={inputRef}
                            className={`h-14 w-full appearance-none text-lg focus:outline-none lg:h-10 lg:text-sm ${
                                show ? 'bg-gray-200' : 'bg-white'
                            }`}
                            onFocus={() => {
                                if (currentItems.length > 0) {
                                    dispatch({
                                        type: ADDRESS_ACTIONS.SHOW_PREDICTIONS,
                                        payload: true,
                                    })
                                }
                            }}
                            type="text"
                            placeholder="Rechercher une adresse..."
                            value={address.inputValue}
                            onChange={handleAddressChange}
                            onKeyDown={handleKeyDown}
                            disabled={show}
                        />
                        {isLoading ? (
                            <LoaderModal
                                customWidth={'w-4'}
                                customHeight="h-4"
                            />
                        ) : (
                            <Cancel
                                className="fa fa-times my-2 ml-2 flex cursor-pointer justify-center text-gray-400 hover:text-gray-600"
                                onClick={() => {
                                    dispatch({
                                        type: ADDRESS_ACTIONS.DELETE_ADDRESS,
                                    })
                                }}
                            />
                        )}
                    </div>
                    {(address.showPredictions || isLoading) &&
                        (currentItems.length > 0 || isLoading) && (
                            <div
                                ref={autocompleteItemsRef}
                                className="absolute z-50 mr-4 mt-1 w-full"
                            >
                                <div className="invisible-scrollbar max-h-addresses z-50 flex w-[95vw] flex-col overflow-y-auto rounded-xl border bg-white shadow-md sm:w-auto lg:mr-4">
                                    {isLoading
                                        ? // Skeleton loader
                                          Array(4)
                                              .fill()
                                              .map((_, index) => (
                                                  <div
                                                      key={index}
                                                      className="flex flex-col px-4 py-2"
                                                  >
                                                      <div className="mb-2 h-5 w-5/6 animate-pulse rounded bg-gray-200"></div>
                                                      <div className="h-4 w-1/2 animate-pulse rounded bg-gray-200"></div>
                                                  </div>
                                              ))
                                        : currentItems.map((item, index) => {
                                              console.log(item)
                                              const props =
                                                  item.properties ||
                                                  item.address
                                              return (
                                                  <div
                                                      key={
                                                          props.place_id ||
                                                          index
                                                      }
                                                      className={`flex cursor-pointer flex-col px-4 py-2 hover:bg-gray-100 ${
                                                          index ===
                                                          focusedItemIndex
                                                              ? 'bg-gray-100'
                                                              : ''
                                                      }`}
                                                      onClick={() =>
                                                          handleClickOnPrediction(
                                                              item
                                                          )
                                                      }
                                                  >
                                                      <div
                                                          className={`flex items-center font-semibold  ${
                                                              item.label
                                                                  ? 'text-secondary-color'
                                                                  : 'text-gray-800'
                                                          }`}
                                                      >
                                                          {item.label ? (
                                                              <HomeUser
                                                                  className={
                                                                      'mr-2 text-secondary-color'
                                                                  }
                                                              />
                                                          ) : null}
                                                          {(props.housenumber
                                                              ? props.housenumber +
                                                                ' '
                                                              : '') +
                                                              (item.label
                                                                  ? item.label +
                                                                    ' | '
                                                                  : '') +
                                                              (props.name ||
                                                                  props.street ||
                                                                  props.address_line1 ||
                                                                  '')}
                                                      </div>
                                                      <div className="text-sm text-gray-600">
                                                          {props.city && (
                                                              <span className="font-medium">
                                                                  {props.city}
                                                              </span>
                                                          )}
                                                          {props.postcode ||
                                                          props.zipCode ? (
                                                              <span className="ml-2 text-gray-500 underline">
                                                                  (
                                                                  {props.postcode ||
                                                                      props.zipCode}
                                                                  )
                                                              </span>
                                                          ) : null}
                                                          {(props.city ||
                                                              props.postcode) &&
                                                              props.county &&
                                                              ', '}
                                                          {props.county && (
                                                              <span className="text-gray-500">
                                                                  {props.county}
                                                              </span>
                                                          )}
                                                      </div>
                                                  </div>
                                              )
                                          })}
                                </div>
                            </div>
                        )}
                </div>
                <button
                    className="align-center mt-auto flex items-center justify-center rounded-md border bg-white hover:border-lighter-blue-green"
                    onClick={showAddressFields}
                >
                    <div className="flex h-14 items-center p-3 lg:h-10">
                        <NavArrowDown
                            className={`dropdown-label transform text-gray-400 ${
                                show
                                    ? 'rotate-180 duration-200 ease-in'
                                    : 'duration-200 ease-out'
                            }`}
                        />
                    </div>
                </button>
            </div>
            <div className={`mx-auto w-full ${visible}`}>
                <div
                    className={`mt-3 transform opacity-0 ${
                        show
                            ? 'translate-y-3 opacity-100 duration-300 ease-in-out'
                            : '-translate-y-3 duration-300'
                    } `}
                >
                    <div className="flex flex-wrap text-base lg:text-xs">
                        <div className="mb-3 w-full md:mb-0  md:w-1/2 md:pr-2">
                            <label
                                className="mb-2 block font-bold  uppercase  tracking-wide text-gray-700"
                                htmlFor="grid-address"
                            >
                                Numéro - libellé de la voie
                            </label>
                            <input
                                className="block h-14 w-full appearance-none rounded border border-gray-200 bg-white px-3 text-lg leading-tight text-gray-700  hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none md:mb-3 lg:h-10 lg:text-sm"
                                id="grid-address"
                                type="text"
                                placeholder="Avenue du Maréchal Leclerc"
                                value={address.street}
                                onChange={(e) =>
                                    dispatch({
                                        type: ADDRESS_ACTIONS.UPDATE_STREET,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="mb-3 w-full md:mb-0  md:w-1/2 md:px-2">
                            <label
                                className="mb-2 block font-bold  uppercase tracking-wide text-gray-700"
                                htmlFor="grid-apt"
                            >
                                Complément d'adresse
                            </label>
                            <input
                                className="block h-14 w-full appearance-none rounded border border-gray-200 bg-white px-3 text-lg leading-tight text-gray-700  hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none lg:h-10 lg:text-sm"
                                id="grid-apt"
                                type="text"
                                placeholder="Batiment C"
                                value={address.secondaryStreet}
                                onChange={(e) =>
                                    dispatch({
                                        type: ADDRESS_ACTIONS.UPDATE_SECONDARY_STREET,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="mb-3 w-full md:mb-0  md:w-1/2 md:pr-2">
                            <label
                                className="mb-2 block font-bold  uppercase tracking-wide text-gray-700"
                                htmlFor="grid-city"
                            >
                                Ville
                            </label>
                            <input
                                className="block h-14 w-full appearance-none rounded border border-gray-200 bg-white px-3 text-lg leading-tight text-gray-700  hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none md:mb-3 lg:h-10 lg:text-sm"
                                id="grid-city"
                                type="text"
                                placeholder="Bordeaux "
                                value={address.city}
                                onChange={(e) =>
                                    dispatch({
                                        type: ADDRESS_ACTIONS.UPDATE_CITY,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="mb-3 w-full md:mb-0  md:w-1/2 md:px-2">
                            <label
                                className="mb-2 block font-bold  uppercase tracking-wide text-gray-700"
                                htmlFor="grid-state"
                            >
                                Département
                            </label>
                            <input
                                className="block h-14 w-full appearance-none rounded border border-gray-200 bg-white px-3 text-lg leading-tight text-gray-700  hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none lg:h-10 lg:text-sm"
                                id="grid-state"
                                type="text"
                                placeholder="Gironde"
                                value={address.county}
                                onChange={(e) =>
                                    dispatch({
                                        type: ADDRESS_ACTIONS.UPDATE_COUNTY,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="mb-3 w-full md:mb-0  md:w-1/2 md:pr-2">
                            <label
                                className="mb-2 block font-bold  uppercase tracking-wide text-gray-700"
                                htmlFor="grid-zip"
                            >
                                Code postal
                            </label>
                            <input
                                className="block h-14 w-full appearance-none rounded border border-gray-200 bg-white px-3 text-lg leading-tight text-gray-700  hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none md:mb-3 lg:h-10 lg:text-sm"
                                id="grid-zip"
                                type="text"
                                placeholder="33000"
                                value={address.zipCode}
                                onChange={(e) =>
                                    dispatch({
                                        type: ADDRESS_ACTIONS.UPDATE_ZIP_CODE,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="mb-3 w-full md:mb-0  md:w-1/2 md:px-2">
                            <label
                                className="mb-2 block font-bold  uppercase tracking-wide text-gray-700"
                                htmlFor="grid-country"
                            >
                                Label / établissement
                            </label>
                            <input
                                className="block h-14 w-full appearance-none rounded border border-gray-200 bg-white px-3 text-lg leading-tight text-gray-700  hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none lg:h-10 lg:text-sm"
                                id="grid-country"
                                type="text"
                                placeholder="CHU"
                                value={address.label}
                                onChange={(e) =>
                                    dispatch({
                                        type: ADDRESS_ACTIONS.UPDATE_LABEL,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="mb-3 w-full md:mb-0 md:pr-2">
                            <label
                                className="mb-2 block font-bold  uppercase tracking-wide text-gray-700"
                                htmlFor="grid-zip"
                            >
                                Code insee
                            </label>
                            <input
                                className="block h-14 w-full appearance-none rounded border border-gray-200 bg-white px-3 text-lg leading-tight text-gray-700  hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none md:mb-3 lg:h-10 lg:text-sm"
                                id="grid-insee"
                                type="text"
                                placeholder="33000"
                                value={address.codeInsee}
                                onChange={(e) =>
                                    dispatch({
                                        type: ADDRESS_ACTIONS.UPDATE_INSEE,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GeoapifyAutocomplete
