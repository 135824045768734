import React from 'react'
import dayjs from 'dayjs'

const ObjectAndDateSecondFormat = ({ row }) => {
    return (
        <div className="w-40 max-w-40 truncate px-6">
            <div className="flex justify-between truncate">
                <div className="flex flex-col truncate">
                    <div className="truncate text-sm font-semibold">
                        {row.runObject.label}
                    </div>
                    <div className="capitalize">
                        {dayjs(row.date).format('ddd D MMM')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ObjectAndDateSecondFormat
