import { ABATEMENT } from '../../../../services/InvoiceUtils'
import { TarifList } from '../../../../services/TarifList'
import { INVOICE_CPAM_ACTIONS } from '../../Regulation/Reducers/InvoiceCpamReducer'

export const SERIES_CPAM_INVOICE_FORM_ACTION = {
    SET_TOTAL_RUNS_PRICES: 'SET_TOTAL_RUNS_PRICES',
    SHOW_MODAL: 'showModal',
    SHOW_EDITING_MODAL: 'showEditingModal',
    IS_LOADING: 'isLoading',
    SET_SHOW_INVOICE_MODAL_FORM: 'SET_SHOW_INVOICE_MODAL_FORM',
    FETCH_INVOICE_CPAM: 'FETCH_INVOICE_CPAM',
    OPEN_ACTION_MENU: 'OPEN_ACTION_MENU',
    OPEN_AMO_STATUS_LIST: 'OPEN_AMO_STATUS_LIST',
    OPEN_AMC_STATUS_LIST: 'OPEN_AMC_STATUS_LIST',
    SET_SHOW_REJECT_HISTORY: 'SET_SHOW_REJECT_HISTORY',
    GET_CPAM_INVOICE: 'GET_CPAM_INVOICE',
    GET_RUNS: 'GET_RUNS',
    RESET_INVOICE_FORM: 'RESET_INVOICE_FORM',
    IS_FECTHING_RUNS: 'IS_FECTHING_RUNS',
    SET_ALL_RUNS_DETAILS: 'SET_ALL_RUNS_DETAILS',
    SET_TOTALS: 'SET_TOTALS',
    UPDATE_ALL_RUNS_INVOICE_DETAILS: 'UPDATE_ALL_RUNS_INVOICE_DETAILS',
    SHOW_RUN_INVOICE_DETAILS_MODAL: 'SHOW_RUN_INVOICE_DETAILS_MODAL',
    UPDATE_RUN_INVOICE_DETAILS: 'UPDATE_RUN_INVOICE_DETAILS',
    FETCH_INVOICE_DATA: 'FETCH_INVOICE_DATA',
    SET_RUNS: 'SET_RUNS',
    SHOW_MODAL_AND_LOAD: 'SHOW_MODAL_AND_LOAD',
    UPDATE_KM_RUN_INVOICE_DETAILS: 'UPDATE_KM_RUN_INVOICE_DETAILS',
    UPDATE_FORM_RUN_INVOICE_DETAILS: 'UPDATE_FORM_RUN_INVOICE_DETAILS',
    UPDATE_FORM_PRICE_BASIS: 'UPDATE_FORM_PRICE_BASIS',
    INIT_PRICE_BASIS: 'INIT_PRICE_BASIS',
    UPDATE_FORM_HOLDER: 'UPDATE_FORM_HOLDER',
    UPDATE_PRICE_BASIS: 'UPDATE_PRICE_BASIS',
    SET_DATE: 'SET_DATE',
    SHOW_CHANGE_STATUS_DATE_MODAL: 'SHOW_CHANGE_STATUS_DATE_MODAL',
}

export const getSeriesInvoiceFormInitialState = () => {
    return {
        showModal: false,
        runs: [],
        loading: true,
        id: null,
        date: new Date(),
        showEditingModal: false,
        tarifs: [],
        priceDetails: [],
        kilometerA: null,
        kilometerB: null,
        kilometerC: null,
        kilometerD: null,
        complementaryPart: '',
        showInvoiceModalForm: false,
        tarifList: TarifList,
        openActionMenu: false,
        openAMOStatusList: false,
        openAMCStatusList: false,
        isFetchingRuns: false,
        runsInvoiceFormsDetails: [],
        totals: null,
        showRunInvoiceDetailsModal: false,
        runsInvoiceFormsDetailsHolder: [],
        priceBasis: null,
        priceBasisForm: null,
        showStatusChangeDateModal: false,
    }
}

export const SeriesCpamInvoiceFormReducer = (state, action) => {
    switch (action.type) {
        case SERIES_CPAM_INVOICE_FORM_ACTION.SHOW_MODAL: {
            return {
                ...state,
                showModal: !state.showModal,
            }
        }
        case SERIES_CPAM_INVOICE_FORM_ACTION.SHOW_EDITING_MODAL: {
            return {
                ...state,
                showEditingModal: action.payload,
            }
        }
        case SERIES_CPAM_INVOICE_FORM_ACTION.IS_LOADING: {
            return {
                ...state,
                loading: action.payload,
            }
        }
        case SERIES_CPAM_INVOICE_FORM_ACTION.GET_CPAM_INVOICE: {
            return {
                ...state,
                ...action.payload,
                loading: false,
                showModal: true,
            }
        }
        case SERIES_CPAM_INVOICE_FORM_ACTION.FETCH_INVOICE_CPAM: {
            return {
                ...state,
                ...action.payload,
                sharedRun: action.payload?.nbPeople > 1,
                abatement:
                    action.payload?.abatement && action.payload?.nbPeople > 1
                        ? ABATEMENT.find(
                              (item) => item.value === action.payload.abatement
                          )
                        : ABATEMENT[0],
                date: action.payload.date
                    ? action.payload.date
                    : new Date(action.payload.date),
            }
        }
        case SERIES_CPAM_INVOICE_FORM_ACTION.OPEN_ACTION_MENU: {
            return {
                ...state,
                openActionMenu: action.payload,
            }
        }
        case SERIES_CPAM_INVOICE_FORM_ACTION.OPEN_AMO_STATUS_LIST: {
            return {
                ...state,
                openAMOStatusList: action.payload,
            }
        }
        case SERIES_CPAM_INVOICE_FORM_ACTION.OPEN_AMC_STATUS_LIST: {
            return {
                ...state,
                openAMCStatusList: action.payload,
            }
        }
        case SERIES_CPAM_INVOICE_FORM_ACTION.SET_SHOW_INVOICE_MODAL_FORM:
            return {
                ...state,
                showInvoiceModalForm: !state.showInvoiceModalForm,
            }
        case SERIES_CPAM_INVOICE_FORM_ACTION.SET_SHOW_REJECT_HISTORY:
            return {
                ...state,
                showRejectHistory: action.payload,
            }
        case SERIES_CPAM_INVOICE_FORM_ACTION.IS_FECTHING_RUNS:
            return {
                ...state,
                isFetchingRuns: true,
            }
        case SERIES_CPAM_INVOICE_FORM_ACTION.GET_RUNS:
            return {
                ...state,
                runs: action.payload,
                isFetchingRuns: false,
            }
        case SERIES_CPAM_INVOICE_FORM_ACTION.SET_ALL_RUNS_DETAILS:
            return {
                ...state,
                runsInvoiceFormsDetails: action.payload,
                runsInvoiceFormsDetailsHolder: action.payload,
            }
        case SERIES_CPAM_INVOICE_FORM_ACTION.SET_TOTALS:
            return {
                ...state,
                totals: action.payload,
                loading: false,
            }

        case SERIES_CPAM_INVOICE_FORM_ACTION.SHOW_RUN_INVOICE_DETAILS_MODAL:
            return {
                ...state,
                showRunInvoiceDetailsModal: !state.showRunInvoiceDetailsModal,
            }
        case SERIES_CPAM_INVOICE_FORM_ACTION.UPDATE_RUN_INVOICE_DETAILS:
            return {
                ...state,
                runsInvoiceFormsDetails: [
                    ...state.runsInvoiceFormsDetails?.map(
                        (runInvoiceDetails) => {
                            if (
                                runInvoiceDetails?.runId ===
                                action.payload.runId
                            ) {
                                return action.payload
                            } else {
                                return runInvoiceDetails
                            }
                        }
                    ),
                ],
            }
        case SERIES_CPAM_INVOICE_FORM_ACTION.UPDATE_FORM_RUN_INVOICE_DETAILS:
            return {
                ...state,
                runsInvoiceFormsDetailsHolder: [
                    ...state.runsInvoiceFormsDetailsHolder?.map(
                        (runInvoiceDetails) => {
                            if (
                                runInvoiceDetails?.runId ===
                                action.payload.runId
                            ) {
                                return action.payload
                            } else {
                                return runInvoiceDetails
                            }
                        }
                    ),
                ],
            }
        case SERIES_CPAM_INVOICE_FORM_ACTION.UPDATE_ALL_RUNS_INVOICE_DETAILS:
            return {
                ...state,
                runsInvoiceFormsDetails: action.payload,
                runsInvoiceFormsDetailsHolder: action.payload,
            }
        case SERIES_CPAM_INVOICE_FORM_ACTION.FETCH_INVOICE_DATA:
            return {
                ...state,
                ...action.payload,
                loading: false,
                priceBasisForm: action.payload.priceBasis,
            }
        case SERIES_CPAM_INVOICE_FORM_ACTION.SET_RUNS:
            return {
                ...state,
                runs: action.payload,
            }

        case SERIES_CPAM_INVOICE_FORM_ACTION.UPDATE_FORM_PRICE_BASIS:
            const updatedRuns = state.runsInvoiceFormsDetailsHolder.map(
                (run) => {
                    return {
                        ...run,
                        priceBasis: action.payload,
                    }
                }
            )
            return {
                ...state,
                runsInvoiceFormsDetailsHolder: updatedRuns,
                priceBasisForm: action.payload,
            }

        case SERIES_CPAM_INVOICE_FORM_ACTION.INIT_PRICE_BASIS:
            return {
                ...state,
                runsInvoiceFormsDetailsHolder:
                    state.runsInvoiceFormsDetailsHolder.map((run) => {
                        return {
                            ...run,
                            priceBasis: action.payload,
                        }
                    }),
                priceBasis: action.payload,
                priceBasisForm: action.payload,
            }
        case SERIES_CPAM_INVOICE_FORM_ACTION.UPDATE_FORM_HOLDER:
            return {
                ...state,
                runsInvoiceFormsDetailsHolder: action.payload,
            }
        case SERIES_CPAM_INVOICE_FORM_ACTION.UPDATE_PRICE_BASIS:
            return {
                ...state,
                priceBasis: action.payload,
            }
        case INVOICE_CPAM_ACTIONS.SET_DATE:
            return {
                ...state,
                date: action.payload,
            }
        case SERIES_CPAM_INVOICE_FORM_ACTION.SHOW_CHANGE_STATUS_DATE_MODAL:
            return {
                ...state,
                showStatusChangeDateModal: !state.showStatusChangeDateModal,
            }
        case SERIES_CPAM_INVOICE_FORM_ACTION.RESET_INVOICE_FORM:
            return getSeriesInvoiceFormInitialState()
        default:
            return state
    }
}
